.c-knit-grid {

   > .cmp-container {
        display: flex;
        flex-wrap: wrap;

        .teaser {
            padding: 0 8px;
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 8px;

            @media only screen and (min-width: $mobile) {
                flex: 0 0 25%;
                max-width: 25%;
                margin-bottom: 55px;
            }

            &:hover {
                .cmp-teaser__action-container {
                    opacity: 1;
                    transform: scale(1);

                    @media only screen and (min-width: $mobile) and (max-width: $tablet) {
                        transform: scale(0.75);
                    }
                }
            }

            .cmp-image__image {
                max-width: 100%;
                width: 100%;
                height: auto;
            }

            .cmp-teaser {
                position: relative;
                mask: url("data-url:resources/images/general/masks/card-grid-mask-image-square.svg") center / contain no-repeat;
            }

            .cmp-teaser__action-link {
                &:hover {
                    .button-background {
                        fill: transparent;
                    }

                    .button-icon {
                        fill: $white;
                    }
                }

                .button-icon {
                    fill: $black;
                    margin-left: 30px;
                    margin-right: 0;
                }

                .button-background {
                    fill: $white;
                    stroke: $white;
                    left: 6px;
                }
            }

            .cmp-teaser__title {
                color: $white;
                font-size: 1.75rem;

                @media only screen and (min-width: $tablet) {
                    font-size: 1.5rem;
                }

                @media only screen and (min-width: $mobile) and (max-width: $tablet) {
                    font-size: 1.125rem;
                    margin: 5px 0 10px 0;
                }
            }

            

            .cmp-teaser__content {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                overflow: auto;

                @media only screen and (min-width: $mobile) and (max-width: $laptop) {
                    padding: 0 2px;
                }
            }

            .cmp-teaser__action-container {
                position: absolute;
                opacity: 0;
                transform: scale(1);
                transition: all 0.75s cubic-bezier(0.63, -0.01, 0.26, 1.49);
                bottom: 30px;

                @media only screen and (min-width: $mobile) and (max-width: $tablet) {
                    bottom: 7px;
                }

                @media only screen and (min-width: $mobile) {
                    transform: scale(0.6);
                }
            }
        }
    }
}