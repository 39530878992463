.xf-header-kids-black {
    #main-header-text {
        background: $black;
        border-bottom: 10px solid $black;
        background-color: $black;
        
        #header-tab {
            width: 61px;
            height: 71px;
            background-image: url('data-url:resources/images/kids/icons/kids-header-navigation.svg');
        }

        #header-tab .cmp-button__icon--header-logo:after {
            display: none;
        }

        .image {
            .cmp-adaptive-image {
                border-bottom: 2px solid #ffffff80;
                margin-left: 1.2rem;
                margin-right: 1.2rem;
    
                @media only screen and (min-width: $tablet) {
                    border-bottom: 0;
                }
            }
    
            .cmp-image__image {
                display: block;
                margin: 0 auto 1.2rem auto;
                width: 48px;
            }
    
        }
    }

    #main-header-text.is-scrolled {
        background-color: $black;
    }

    #social-links:hover {
        .cmp-button__icon--facebook {
            background-image: url("data-url:resources/images/kids/icons/facebook-light-black.svg");
        }

        .cmp-button__icon--instagram {
            background-image: url("data-url:resources/images/kids/icons/instagram-light-black.svg");
        }

        .cmp-button__icon--x-social {
            background-image: url("data-url:resources/images/kids/icons/x-social-light-black.svg");
        }
    }

    #social-links {
        .cmp-button__icon--facebook {
            background-image: url("data-url:resources/images/kids/icons/facebook.svg");

            &:hover {
                background-image: url("data-url:resources/images/kids/icons/facebook.svg");
            }
        }

        .cmp-button__icon--instagram {
            background-image: url("data-url:resources/images/kids/icons/instagram.svg");

            &:hover {
                background-image: url("data-url:resources/images/kids/icons/instagram.svg");
            }
        }

        .cmp-button__icon--x-social {
            background-image: url("data-url:resources/images/kids/icons/x-social-white.svg");

            &:hover {
                background-image: url("data-url:resources/images/kids/icons/x-social-white.svg");
            }
        }
    }


    #main-nav {

        ul > li {
            &.is-open {
                background-color: $color-text-footer;
                border-bottom: 0;

                >a {
                    color: $dark-grey;
                }

                ul > li {
                    border-bottom: 2px solid $dark-grey;
                    >a {
                        color: $dark-grey;
                    }
                }
            }

            @media only screen and (max-width: $tablet) {
                border-bottom: 2px solid $dark-grey;
            }
        }

        &:hover {

            ul > li:not(:hover) {
                >a {
                    color: $dark-grey;
                }
            }
        }
    }
}