.text {
  a {
    text-decoration: underline;
  }
}

.font-size-4-rem p {
  font-size: 3rem;
  line-height: 2.8rem;
  @media only screen and (min-width: $tablet) {
    font-size: 4rem;
    line-height: 4rem;
  }
}

.font-size-3-rem p {
  font-size: 1.75rem;
  line-height: 2rem;
  @media only screen and (min-width: $tablet) {
    font-size: 3rem;
    line-height: 3.375rem;
  }
}

.font-size-2-75-rem p {
  font-size: 1.75rem;
  line-height: 2rem;
  @media only screen and (min-width: $tablet) {
    font-size: 2.75rem;
    line-height: 3rem;
  }
}

.font-size-1-75-rem p {
  font-size: 1.5rem;
  line-height: 1.625rem;
  @media only screen and (min-width: $tablet) {
    font-size: 1.75rem;
    line-height: 2.188rem;
  }
}

.font-size-1-375-rem p {
  font-size: 1.375rem;
  line-height: 2rem;
}

.font-size-1-25-rem p {
  font-size: 1.25rem;
  line-height: calc(10px + 2ex);
}

.font-size-1-25-rem p {
    font-size: 1.25rem;
    line-height: calc(10px + 2ex);
}

.font-size-1-rem p {
    font-size: 1rem;
    line-height: calc(10px + 2ex);
}
 
.txt-color-white p {
    color: white;
}

.font-weight-bold p {
  @include font-innocent-bold;
}

.text {
  a {
    color: inherit;
  }

  b {
    @include font-innocent-bold;
  }
  
  u {
    margin-bottom: 1.5rem;
    display: inline-block;
    position: relative;
    text-decoration: none;

    svg {
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
      pointer-events: none;
      transition: 0.5s;

      path {
        stroke-dashoffset: 0 !important;
      }
    }
  }
}

@each $key,$val in $colors {
  .txt-color-#{$key} {
    .cmp-text {
      color: #{$val};   
  
      u svg path {
        stroke: #{$val};
      }
    }
  }

  .t-underline-#{$key} {
    .cmp-text {
      u svg path  {
        stroke: #{$val};
      }
    }
  }
}

