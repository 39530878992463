.c-sticky > .cmp-container {
  .adaptiveImage,
  .teaser { 
    @media screen and (min-width: $laptop) {
      position: sticky;
      top: 20px;
    }
  }
}
  
