.t-kids-common {
  background-image: url(data-url:resources/images/kids/icons/teaser-kids-common.svg);
  background-size: 100% 100%;
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
    width: 100%;
    height: 50%;
    background-color: #ecf6df;
  }
  &:after {
    content: "";
    display: block;
    background-color: white;
    position: absolute;
    z-index: -1;
    bottom: 0;
    height: 50%;
    width: 100%;
  }

  @media only screen and (max-width: $mobile) {
    background-image: url(data-url:resources/images/kids/icons/teaser-kids-common-mobile.svg);
    background-position: bottom;
  }

  .cmp-teaser {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    max-width: 1210px;
    margin-left: auto;
    margin-right: auto;
    padding: 110px 70px;

    @media only screen and (max-width: $mobile) {
      flex-direction: column-reverse;
      align-items: inherit;
      padding: 160px 40px 100px;
    }
  }

  .cmp-teaser__content,
  .cmp-teaser__image {
    padding: 0 15px;
    flex: 0 0 100%;
    max-width: 100%;

    @media only screen and (min-width: $mobile) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  .cmp-teaser__image {
    display: grid;
    margin: 0;
  }

  .cmp-adaptive-image {
    grid-row: 1;
    grid-column: 1;
    width: calc(100% - 100px);
    margin: auto;

    @media only screen and (max-width: $tablet) {
      width: 77%;
    }
  }

  picture {
    -webkit-mask: url(data-url:resources/images/kids/icons/teaser-common-mask.svg)
      center / contain no-repeat;
    object-fit: cover;
    padding-bottom: 100%;
    overflow: hidden;
    position: relative;
    display: block;
  }

  .cmp-image__image {
    width: 100%;
    max-width: none;
    position: absolute;
    top: 50%;
    right: 0;
    bottom: 0;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .cmp-teaser__image {
    svg {
      grid-column: 1;
      grid-row: 1;
      margin: auto;
      height: auto;
      max-width: 100%;
    }
  }

  .cmp-teaser__content {
    padding: 0 15px 0 50px;
    text-align: left;

    @media only screen and (max-width: $mobile) {
      text-align: center;
      padding: 0;
    }

    .cmp-teaser__title {
      @include font-innocent-light;

      @media only screen and (max-width: $mobile) {
        padding-top: 50px;
      }
    }

    .cmp-teaser__description p {
      @include font-innocent-bold;
      color: $white;
      max-width: 540px;
      font-size: 28px;
      line-height: 34px;
    }
  }

  .button-icon {
    display: none;
  }

  .cmp-teaser__action-link {
    margin-right: 10px;
    font-size: 20px;
    line-height: 26px;
  }

  @each $key, $val in $kids-colors {
    &.t-scheme-#{$key} {
      .cmp-teaser__title {
        color: #{$val};
      }

      .cmp-teaser__action-link {
        color: #{$val};

        .button-background {
          fill: rgba(171, 92, 87, 0);
          stroke: #{$val};
        }

        &:hover {
          .button-background {
            fill: #{$val};
          }
        }
      }
    }

    &.t-kids-potato-#{$key} {
      .cmp-teaser__image {
        svg path {
          fill: #{$val};
        }
      }
    } 
  }
}
