@mixin teaser-common {
  .cmp-teaser {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    @media only screen and (max-width: $mobile) {
      flex-direction: column-reverse;
      align-items: inherit;
      overflow: hidden;
    }
  }

  .cmp-teaser__content,
  .cmp-teaser__image {
    padding: 0 15px;
    flex: 0 0 100%;
    max-width: 100%;
    @media only screen and (min-width: $mobile) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  .cmp-teaser__description {
    u {
      margin-bottom: 1.5rem;
      display: inline-block;
      position: relative;
      text-decoration: none;
  
      svg {
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        pointer-events: none;
        transition: 0.5s;
  
        path {
          stroke-dashoffset: 0 !important;
        }
      }
    }
  }

  .cmp-image__image {
    width: 100%;
    height: auto;
  }

  .cmp-teaser__image {
    position: relative;
    text-align: center;
    @media only screen and (max-width: $mobile) {
      margin-bottom: 2rem;
    }
  }

  .cmp-teaser__pretitle {
    font-size: 3rem;
    line-height: 3.375rem;
    margin: 0;
    @include font-innocent-bold;
    @media only screen and (max-width: $mobile) {
      font-size: 1.75rem;
      line-height: 2rem;
    }
  }
}

@mixin teaser-image-right {
  &.t-img-right {
    .cmp-teaser {
      flex-direction: row;
      @media only screen and (max-width: $mobile) {
        flex-direction: column-reverse;
      }
    }
  }
}

@mixin teaser-mask-curvy {
  &.t-mask-curvy {
    picture img {
      border-radius: 20px;
    }
  }
}
