.t-olympics {

    @include teaser-common;

    .cmp-teaser {

        &::after {
            @media only screen and (min-width: calc($mobile + 1px)) {
                content: '';
                position: absolute;
                background-image: url(data-url:resources/images/olympics/running-woman.svg);
                bottom: -70px;
                left: -80px;
                width: 220px;
                height: 324px;
                scale: 0.8;
            }

            @media only screen and (min-width: $laptop) {
                scale: 1;
                bottom: -90px;
                left: -110px;
            }
        }

        .cmp-teaser__title {
            color: #ae2573;
        }

        .cmp-teaser__action-link {
            color: #fff;

            .button-background path {
                fill: #ae2573;
            }

            .button-icon path {
                fill: #fff;
            }
        }

        .cmp-teaser__image {
            display: grid;

            svg {
                grid-column: 1;
                grid-row: 1;
                margin: auto;
                height: auto;
                max-width: 100%;

                path {
                    fill: #ae2573;
                }
            }
        }

        .cmp-adaptive-image {
            grid-row: 1;
            grid-column: 1;
            width: calc(100% - 100px);
            margin: auto;
        }

        picture {
            -webkit-mask: url(data-url:resources/images/general/masks/common-split-mask-image.svg) center / contain no-repeat;
            object-fit: cover;
            padding-bottom: 100%;
            overflow: hidden;
            position: relative;
            display: block;
        }

        .cmp-image__image {
            width: 100%;
            max-width: none;
            position: absolute;
            top: 50%;
            right: 0;
            bottom: 0;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }
    }
}