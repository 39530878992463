.ai-middle-left {
    position: relative;

    .cmp-image__image {
        position: absolute;
        top: 0;
        left: 130px;
        width: 270px;

        @media only screen and (max-width: $tablet) {
            width: 200px;
            left: 0;
        }

        @media only screen and (max-width: $mobile) {
            width: 140px;
        }

    }

}