.c-olympics-form {
    position: relative;
    background: rgba(213, 81, 131, 1);

    &:before,
    &:after {
        content: "";
        display: block;
        background-size: cover;
        width: 100%;
        position: relative;
        background-size: cover;
    }
    &:before {
        background-image: url(data-url:resources/images/olympics/top-wave.svg);
        padding-top: calc((62 / 1440) * 100%); /* Aspect ratio padding-top: height 62, width 1440 */
    }

    &:after {
        background-image: url(data-url:resources/images/olympics/supporters-mobile.svg);
        background-position: calc(50% - 30px) 50%;
        padding-top: 281px;   /* svg height */
        bottom: -2px;
        
        @media only screen and (min-width: $laptop) {
            background-image: url(data-url:resources/images/olympics/supporters.svg); 
            background-position: bottom;
            padding-top: calc((281 / 1440) * 100%);  /* Aspect ratio padding-top: height 281, width 1440 */
        }
    }

    > .cmp-container {
        position: relative;
    }
}