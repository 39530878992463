.ai-kids-activity {
  background-image: url("data-url:resources/images/kids/icons/divider-line.svg");
  background-repeat: no-repeat;
  background-position: 0px 60px;
  background-size: 100%;

  @media only screen and (max-width: $tablet) {
    padding: 20px 90px;
  }

  @media only screen and (max-width: $mobile) {
    background-image: none;
    padding: 0;
  }

  .cmp-image__image {
    margin: 0 auto;
    max-width: 1120px;
    display: block;
    mask: url("data-url:resources/images/kids/icons/teaser-header.svg") center /
      contain no-repeat;

    @media only screen and (max-width: $mobile) {
      mask: url("data-url:resources/images/kids/icons/teaser-header-mobile.svg")
        center / contain no-repeat;
    }
  }
}
