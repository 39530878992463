.cmp-embed {
  position: relative;

  .cmp-embed__youtube {

    &.cmp-embed--thumbnail {
      img {
        border-radius: 16px;
        width: 100%;
        height: 100%;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        overflow: hidden;
      }
    }

    .video-button-black {
      width: 105px;
      height: 105px;
      cursor: pointer;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @media only screen and (max-width: 600px) {
        width: 60px;
        height: 60px;
      }

      .video-potato {
        fill: $black;
      }

      .video-button {
        fill: $white;
      }
    }

    .cmp-embed__youtube-iframe {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }

    .hidden {
      img {
        opacity: 0;
        height: 0;
        transition-delay: 0s, 800ms;
      }
    }
  }

  .cmp-embed__youtube :hover {

    .video-potato {
      fill: $white;
    }

    .video-button {
      fill: $black;
    }
  }

  .yv-video-portait & {
    @media only screen and (max-width: $mobile) {
      aspect-ratio: 9 / 16;
    }
  }
}

#subscription-form {
  position: initial; 
}