.t-kids-header {
    .cmp-teaser { 
        display: flex;
        justify-content: space-between;
        max-width: 1340px;
        margin: 0 auto;
        padding: 140px 20px;

        @media only screen and (max-width: $laptop) {
            padding: 190px 20px;
        }
        
        @media only screen and (max-width: $tablet) {
            display: inherit;
        }

        @media only screen and (max-width: $mobile) {
            padding: 50px 20px;
        }
    }

    .cmp-teaser__content {
        text-align: left;
        max-width: 560px;
        padding-right: 30px;
        
        @media only screen and (max-width: $tablet) {
            max-width: 670px;
            padding-right: 0;
            margin: 20px auto 0 auto;
        }

        @media only screen and (max-width: $mobile) {
            max-width: 400px;
        }

        .cmp-teaser__title {
            @include font-innocent-bold;
            font-size: 85px;
            line-height: 95px;
            max-width: 525px;
            color: $black;

            @media only screen and (max-width: $mobile) {
                font-size: 48px;
                line-height: 58px;
            }

        }

        .cmp-teaser__description {
            p {
                @include font-innocent-light;
                font-size: 22px;
                line-height: 32px;
                color: $black;

                @media only screen and (max-width: $tablet) {
                    font-size: 20px;
                    line-height: 24px;
                }
            }
        }
    }

    .cmp-teaser__image {
        margin-top: 70px;
        flex: 1;

        @media only screen and (max-width: $tablet) {
            margin: 0 auto;
            max-width: 670px;

        }

        @media only screen and (max-width: $mobile) {
            max-width: 400px;
            margin: 0 auto;
        }
    }
}