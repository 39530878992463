//== Variables

@import 'site/variables';
@import 'site/grid';

//== Site global

// @import 'npm:normalize.css';
@import 'site/fonts';
@import 'site/base';

//== Core components

@import 'components/text/_text.scss';
@import 'components/text/_text_styles.scss';
@import 'components/text/_text_flyout.scss';
@import 'components/text/_text_signage.scss';
@import 'components/text/_text_simpletext.scss'; 
@import 'components/text/_text_olympics.scss'; 


@import 'components/title/_title.scss';
@import 'components/title/_title_styles.scss';
@import 'components/title/_title_marker.scss';
@import 'components/title/_title_olympics.scss';

@import 'components/adaptive-image/_adaptive_image_kids_header.scss';
@import 'components/adaptive-image/_adaptive_image_kids_top_right.scss';
@import 'components/adaptive-image/_adaptive_image_kids_middle_left.scss';
@import 'components/adaptive-image/_adaptive_image_kids_bottom_left.scss';
@import 'components/adaptive-image/_adaptive_image_kids_bottom_right.scss';
@import 'components/adaptive-image/_adaptive_image_kids_top_left.scss';
@import 'components/adaptive-image/_adaptive_image_curvy_corners.scss';
@import 'components/adaptive-image/_adaptive_image_innocent_header.scss';
@import 'components/adaptive-image/_adaptive_image_olympics.scss';


@import 'components/button/_button.scss';
@import 'components/button/_button_kids.scss';
@import 'components/button/_button_social.scss';
@import 'components/button/_button_olympics.scss';


@import 'components/separator/_separator.scss';
@import 'components/separator/_separator_small.scss';
@import 'components/separator/_separator_standard.scss';
@import 'components/separator/_separator_large.scss';
@import 'components/separator/_separator_extra_large.scss';

@import 'components/teaser/_teaser_mixins.scss'; 
@import 'components/teaser/_teaser.scss';
@import 'components/teaser/_teaser_common.scss';
@import 'components/teaser/_teaser_wild_area.scss';
@import 'components/teaser/_teaser_kids_header.scss';
@import 'components/teaser/_teaser_kids_vertical_r.scss';
@import 'components/teaser/_teaser_kids_common.scss';
@import 'components/teaser/_teaser_vertical.scss';
@import 'components/teaser/_teaser_olympics.scss';


//== Container components

@import 'components/container/_container_wave.scss';
@import 'components/container/container_position';
@import 'components/container/_container_innocent_slider.scss'; 
@import 'components/container/_container_teaser_slider.scss';
@import 'components/container/_container_kids_teaser_slider.scss'; 
@import 'components/container/_container_kids_social_feed.scss';
@import 'components/container/_container_kids_two_teasers.scss';
@import 'components/container/_container_kids_teaser_list.scss';
@import 'components/container/_container_nature_section_header.scss';
@import 'components/container/_container_nature_header_separator.scss';
@import 'components/container/_container_kids_entry_page.scss';
@import 'components/container/_container_kids_activity_badge.scss';
@import 'components/container/_container_kids_activity-section-separator.scss';
@import 'components/container/_container_flip_card_grid.scss';
@import 'components/container/_container_simple_carousel.scss';
@import 'components/container/_container_contacts.scss';
@import 'components/container/_container_grid_up_down_3.scss'; 
@import 'components/container/_container_grid_up_down_4.scss';
@import 'components/container/_container_teaser_list.scss';
@import 'components/container/_container_knit_grid.scss';
@import 'components/container/_container_sticky.scss';
@import 'components/container/_container_banner.scss';
@import 'components/container/_container_olympics_boxes.scss';
@import 'components/container/_container_olympics_slider.scss';
@import 'components/container/_container_olympics_form.scss';
@import 'components/container/_container_olympics_top.scss';
@import 'components/container/_container_olympics_carousel.scss';
@import 'components/container/_container_olympics_video.scss';
@import 'components/container/_container_olympics_medal_home.scss';


@import 'components/accordion/_accordion_kids.scss';
@import 'components/accordion/_accordion.scss';


//== Fragments (CF + XF) components

@import 'components/experiencefragment/_experiencefragment.scss';  
@import 'components/experiencefragment/_experiencefragment_footer.scss';
@import 'components/experiencefragment/_experiencefragment_header.scss';
@import 'components/experiencefragment/_experiencefragment_header_text.scss';
@import 'components/experiencefragment/_experiencefragment_kids_header.scss';
@import 'components/experiencefragment/_experiencefragment_kids_header_text.scss';
@import 'components/experiencefragment/_experiencefragment_kids_footer.scss';
@import 'components/experiencefragment/_experiencefragment_olympic_games_footer.scss';
@import 'components/experiencefragment/_experiencefragment_olympic_games_header_text.scss';

//== Embed components

@import 'components/embed/_embed_youtube.scss';
@import 'components/embed/_embed_full_screen.scss';
@import 'components/embed/_embed_youtube_olympics.scss';