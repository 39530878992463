.c-flip-card-grid,
.c-flip-card-grid-full-width {
  >.cmp-container {
    @media only screen and (min-width: $mobile) {
      max-width: 1210px;
      margin: 0 auto;
      display: flex;
    }

    >.container {
      flex-basis: 50%;
    }

    >.container:nth-child(1) {
      >.cmp-container {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        .teaser {
          flex: 1 1 calc(100% - 20px);
          width: calc(100% - 20px);
        }
      }
    }

    >.container:nth-child(2) {
      >.cmp-container {
        .cmp-teaser__description * {
          color: $white;
    
          @media only screen and (min-width: calc($mobile + 1px)) {
            font-size: 16px;
            line-height: 1.4rem;
          }
    
          @media only screen and (min-width: $tablet) {
            line-height: 1.5rem;
            font-size: 20px;
          }
        }
        @media only screen and (min-width: $mobile) {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
        }

        .teaser:nth-child(1),
        .teaser:nth-child(2) {

          .cmp-teaser__title {
            line-height: 1.1;
            margin-top: 0;

            @media only screen and (max-width: $mobile) {
              font-size: 20px;
              margin-top: 20px;
              margin-bottom: 0;
            }
          }
        }

        .teaser:nth-child(3),
        .teaser:nth-child(4),
        .teaser:nth-child(5) {
          flex: 1 1 calc(100% - 20px);
          width: calc(100% - 20px);
        }
      }
    }
  }

  .teaser {
    margin-left: 10px;
    margin-right: 10px;

    @media only screen and (max-width: $mobile) {
      margin-bottom: 10px;
    }

    img {
      max-width: 100%;
      width: 100%;
      height: auto;
    }

    .cmp-teaser {
      position: relative;
      width: 100%;
      mask: url("data-url:resources/images/general/masks/card-grid-mask-image-square.svg") center / contain no-repeat;
    }

    .cmp-teaser__action-link {

      @media only screen and (max-width: $tablet) {
        height: 0;
      }

      &:hover {
        .button-background {
          fill: transparent;
        }

        .button-icon {
          fill: $white;
        }
      }

      .button-icon {
        fill: $black;
      }

      .button-background {
        fill: $white;
        stroke: $white;
      }
    }

    .cmp-teaser__description {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
    }

    .cmp-teaser__description * {
      color: $white;
      font-size: 16px;
      line-height: 1.4rem;
      margin-top: revert;

      @media only screen and (min-width: calc($tablet + 1px)) and (max-width: $laptop) {
        font-size: 20px;
        line-height: 1.5rem;
      }

      @media only screen and (min-width: calc($laptop + 1px)) {
        font-size: 20px;
        line-height: 1.5rem;
      }
    }

    .cmp-teaser__description p {
      @media only screen and (max-width: $mobile) {
        margin: 20px 0;
      }
    }

    .cmp-teaser__title {
      transition: color 0.8s ease;
      font-size: 20px;
      line-height: initial;
      margin-bottom: 0;

      @media only screen and (min-width: $mobile) {
        font-size: 36px;
        line-height: 2.5rem;
        margin-bottom: 20px;  
      }

      @media only screen and (min-width: calc($mobile + 1px)) and (max-width: $laptop) {
        margin-bottom: 0;
      }

      @media only screen and (min-width: calc($laptop + 1px)) {
        font-size: 44px;
        line-height: 3rem;
      }
    }

    &:hover {
      .cmp-teaser__title {
        color: $white;
      }

      .cmp-teaser__description,
      .cmp-teaser__action-container,
      .secondary-image {
        opacity: 1;
        transform: scale(1);
      }
    }

    .cmp-teaser {
      position: relative;
    }

    .cmp-teaser__content {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 10px 15px;
      overflow: auto;
      transition: background-color 0.8s ease;

      @media only screen and (max-width: $mobile) {
        padding: 0 15px;
      }
    }


    .cmp-teaser__description,
    .cmp-teaser__action-container,
    .secondary-image {
      opacity: 0;
      transform: scale(0.5);
      transition: all 0.75s cubic-bezier(0.63, -0.01, 0.26, 1.49);
    }

    .cmp-teaser__action-container {
      min-height: 44px;

    }
  }

  .secondary-image {
    display: block;
    margin-bottom: 0;

    .cmp-image__image {
      width: auto;
      max-height: 200px;

      @media only screen and (max-width: $tablet) {
        max-height: 120px;
      }

      @media only screen and (max-width: $mobile) {
        max-height: 75px;
      }
    }
  }

  .t-flip-bg-strawberry {
    .cmp-teaser__content {
      &:hover {
        background-color: #f6b5ba;
      }
    }
  }

  .t-flip-bg-algae {
    .cmp-teaser__content {
      &:hover {
        background-color: $algae;
      }
    }
  }

  .t-flip-bg-broccoli {
    .cmp-teaser__content {
      &:hover {
        background-color: $broccoli;
      }
    }
  }

  .t-flip-bg-sky {
    .cmp-teaser__content {
      &:hover {
        background-color: $sky;
      }
    }
  }

  .t-flip-bg-water {
    .cmp-teaser__content {
      &:hover {
        background-color: $water;
      }
    }
  }

  .t-flip-bg-mango {
    .cmp-teaser__content {
      &:hover {
        background-color: $mango;
      }
    }
  }

  .t-flip-bg-apple {
    .cmp-teaser__content {
      &:hover {
        background-color: $apple;
      }
    }
  }

  .t-flip-bg-spinach {
    .cmp-teaser__content {
      &:hover {
        background-color: $spinach;
      }
    }
  }

  .t-flip-bg-spinach {
    .cmp-teaser__content {
      .cmp-teaser__title {
        display: none;
      }

      &:hover {
        .cmp-teaser__title {
          display: block;
        }
      }
    }
  }
}

.c-flip-card-grid {
  >.cmp-container {
    >.container:nth-child(2) {
      >.cmp-container {

        .teaser:nth-child(1),
        .teaser:nth-child(2) {
          @media only screen and (min-width: $mobile) {
            flex-basis: calc(100% / 2 - 20px);
            width: calc(100% / 2 - 20px);
          }

          img {
            @media only screen and (min-width: $mobile) {
              width: 320px;
            }
          }

          .cmp-teaser__title {
            font-size: 22px;

            @media only screen and (max-width: $mobile) {
              font-size: 20px;
            }
          }

          .cmp-teaser__description * {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.c-flip-card-grid-full-width {
  >.cmp-container {
    >.container:nth-child(2) {
      >.cmp-container {

        .teaser:nth-child(1),
        .teaser:nth-child(2) {

          .cmp-teaser__title {
            margin-top: 20px;
          }

          @media only screen and (min-width: $mobile) {
            flex-basis: calc(100% - 20px);
            width: calc(100% - 20px);
          }
        }
      }
    }
  }
}