.btn-kids {
  text-align: center;

  .cmp-button {
    @include font-innocent-bold;
    font-size: 20px;
    text-decoration: none;
    display: inline-block;
    border-radius: 60px;
    padding: 10px 25px;
    position: relative;
    cursor: pointer;

    &:before,
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 60px;
      transition: background-color .3s ease-in-out;
    }

    &:before {
      background-color: map-get($kids-colors, sra);
      top: 0;
      left: 0;
      z-index: 2;
    }

    &:after {
      top: 6px;
      left: 6px;
    }

    &:hover {
      &:after {        
        background-color: map-get($kids-colors, apple);
      }
    }

  }
 
  .cmp-button__text {
    color: map-get($colors, sharpieBlack);
    position: relative;
    z-index: 3;
    display: block;
  }
}
