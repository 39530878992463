.ai-kids-top-right {
    position: relative;

    .cmp-image__image {
        position: absolute;
        top: 0;
        right: 0;
        width: 320px;

        @media only screen and (max-width: $tablet) {
            width: 230px;
        }

        @media only screen and (max-width: $mobile) {
            width: 130px;
        }
    }
}