.olympics-yt-video {
    .cmp-embed {
        .cmp-embed__youtube {
            .video-potato {
                fill: #E74883;
            }

            &:hover {
                .video-potato {
                    fill: map-get($kids-colors, beetroot );
                }
                .video-button {
                    fill: white;
                }
            }
        }
    }

    &[class*="flyout-embed"]  {
        .cmp-embed__info {
            position: absolute;
        }

        .cmp-embed__title {
            @include font-innocent-super-scrawl;
            font-size: 30px;
            line-height: 26px;
            font-weight: 400;
            color: #202721;
            min-width: 210px;
            max-height: 55px;
            overflow: hidden;
            text-align: center;

            @media only screen and (min-width: calc($mobile + 1px)) {
                min-width: auto;
                max-width: 130px;
            }
        }

        .cmp-embed__title::after {
            content: "";
            position: absolute;
            display: block;
            width: 45px;
            height: 45px;
            background: center / contain no-repeat url("data-url:resources/images/olympics/arrow-flyout-left.svg");
        }
    }

    &.flyout-embed-top {
        .cmp-embed__info {
            top: -90px;
            left: 50%;
            transform: translateX(calc(-50% - 24px)) rotate3d(0, 0, 1, -7deg);

            @media only screen and (min-width: calc($mobile + 1px)) {
                transform: translateX(calc(-50% - 20px)) rotate3d(0, 0, 1, -7deg);
            }

            .cmp-embed__title::after {
                rotate: -96deg;
                right: -30px;
                top: unset;
                bottom: 0;
                transform-origin: right center;

                @media only screen and (max-width: $mobile) {
                    background: center / cover no-repeat url("data-url:resources/images/olympics/olympics-flyout-arrow.svg");
                    width: 35px;
                    height: 83px;
                    right: -45px;
                    top: 0;
                    rotate: 0deg;
                }
            }
        }
    }

    &.flyout-embed-right {
        .cmp-embed__info {
            bottom: -60px;
            left: 50%;
            transform: translate(-50%, 100%);

            @media only screen and (min-width: calc($mobile + 1px)) {
                top: 50%;
                left: unset;
                bottom: unset;
                right: 0;
                transform: translate(130%, calc(-50% - 20px)) rotate3d(0, 0, 1, -7deg);
            }
        }

        .cmp-embed__title::after {
            left: 50%;
            top: 0;
            transform-origin: top;
            transform: translate(-100%, -30%) scaleY(-1)rotate(-63deg);

            @media only screen and (min-width: calc($mobile + 1px)) {
                left: 20%;
                top: -15%;
                transform: scaleY(-1);
            }
        }
    }
}