.c-banner {
  > .cmp-container {
    .top-wave {
      animation: wave_reverse_top 16s cubic-bezier(0.36, 0.45, 0.63, 0.53)
        infinite;
      rotate: 180deg;
      background: none;
      width: 200vw;
      top: -1px;
    }
  }
}

@keyframes wave_reverse_top {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100vw);
  }
}
