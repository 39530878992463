.c-innocent-slider {
    .swiper-slide {

      .cmp-image__image {
        transition: transform 0.5s ease, -webkit-transform 0.5s ease;
      }

      &:nth-child(odd) {
        .cmp-image__image {
          transform: rotate(10deg) scale(0.75);
        }
      }
  
      &:nth-child(2n) {
        .cmp-image__image {
          transform: rotate(-10deg) scale(0.75);
        }
      }

      &.swiper-slide-active {
        .cmp-image__image {
          transform: scale(1);
        }
      }

    }

    .swiper-pagination {
      text-align: center;
      margin-top: 40px;

      .swiper-pagination-bullet  {
        margin: 0 0.188rem;
        cursor: pointer;
      }
    }

    .swiper-pagination-bullet {
      svg {
        fill: $red;
      }
    }

  .swiper-pagination-bullet-active {
      svg {
        fill: map-get($colors, sharpieBlack);
      }
    }
    .swiper-button-next,
    .swiper-button-prev {
      position: absolute;
      transform: translateY(-100%);
      bottom: 0;
      z-index: 1;
      background-repeat: no-repeat;
      cursor: pointer;
      transition: transform 0.3s cubic-bezier(0.63, -0.01, 0.26, 1.49);
      display: none;
      &:hover {
        transform: scale(0.9) translateY(-100%);
      }

      @media only screen and (min-width: $tablet) {
        display: block;
        top: 50%;
        bottom: initial;
        transform: translateY(-50%);
        &:hover {
          transform: scale(0.9) translateY(-50%);
        }
      }
    }

    .swiper-button-next {
      width: 40px;
      height: 30px;
      background-image: url('data-url:resources/images/general/icons/slider-arrow-next.svg');
      right: 20px;
    }
  
    .swiper-button-prev {
      width: 40px;
      height: 30px;
      background-image: url('data-url:resources/images/general/icons/slider-arrow-prev.svg');
      left: 20px;
    }

    .swiper-button-disabled {
      opacity: .5;
      cursor: default;
    }
}