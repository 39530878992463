.c-nature-header-separator {

    > .cmp-container {
        position: relative;
        z-index: 2;
    }

    .adaptiveImage:nth-child(2) {
        margin-right: 25px;
       
        @media only screen and (max-width: 650px) {
            position: relative;
            top: 20px;
        }
        
        @media only screen and (min-width: calc($laptop + 1px)) { 
            margin-left: 190px;
            margin-right: 0;
            max-width: 1200px;
        }

    }
    .ai-kids-top-left {
        position: absolute; 
        left: 0;
        top: 0;
        z-index: 1;

        .cmp-image__image {
            width: 250px;

            @media only screen and (max-width: $laptop) {
                width: 200px;
            }
    
            @media only screen and (max-width: $tablet) {
                width: 140px;
            }
    
            @media only screen and (max-width: $mobile) {
                width: 100px;
            }
        }
    }

    .ai-kids-top-right {
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;

        .cmp-image__image {
            width: 250px;

            @media only screen and (max-width: $laptop) {
                width: 200px;
            }
    
            @media only screen and (max-width: $tablet) {
                width: 140px;
            }
    
            @media only screen and (max-width: $mobile) {
                width: 100px;
            }
        }
    }
}