.xf-header-kids-black {
    #main-header {
        background: $black;
        border-bottom: 10px solid $black;
        background-color: $black;

        #header-tab {
            width: 61px;
            height: 71px;
            background-image: url('data-url:resources/images/kids/icons/kids-header-navigation.svg');
        }

        #header-tab .cmp-button__icon--header-logo:after {
            display: none; 
        }
    }

    #main-header.is-scrolled .cmp-navigation__item--active .cmp-navigation__group {
        background-color: $black;
    }
    #social-links:hover {
        .cmp-button__icon--facebook {
            background-image: url("data-url:resources/images/kids/icons/facebook-light-black.svg");
        }

        .cmp-button__icon--instagram {
            background-image: url("data-url:resources/images/kids/icons/instagram-light-black.svg"); 
        }

        .cmp-button__icon--x-social {
            background-image: url("data-url:resources/images/kids/icons/x-social-light-black.svg");
        }
    }

    #social-links {
        .cmp-button__icon--facebook {
            background-image: url("data-url:resources/images/kids/icons/facebook.svg");
    
            &:hover {
                background-image: url("data-url:resources/images/kids/icons/facebook.svg") ;
            }
        }
    
        .cmp-button__icon--instagram {
            background-image: url("data-url:resources/images/kids/icons/instagram.svg");
    
            &:hover {
                background-image: url("data-url:resources/images/kids/icons/instagram.svg");
            }
        }
    
        .cmp-button__icon--x-social {
            background-image: url("data-url:resources/images/kids/icons/x-social-white.svg");
    
            &:hover {
                background-image: url("data-url:resources/images/kids/icons/x-social-white.svg");
            }
        }
    }
   
    #main-navigation {
        .cmp-navigation__item--level-0 {
            background-color: $black;

            @media only screen and (max-width: $tablet) {
                border-bottom: 2px solid rgba(255, 255, 255, 0.5);
            }
        }

        .cmp-navigation__item--level-0.is-open {
            background-color: 2px solid rgba(255, 255, 255, 0.5);
            border-bottom: 0;
        }

        .cmp-navigation__item--level-0.is-open .cmp-navigation__group .cmp-navigation__item--level-1 {
                border-bottom: 2px solid rgba(255, 255, 255, 0.5);
        }

        .cmp-navigation__item--level-1>.cmp-navigation__item-link {
            color: rgba(255, 255, 255, 0.5);
        }

        .cmp-navigation__item--level-1>.cmp-navigation__item-link:hover {
            color: white;

        }
    }

    #main-navigation:hover .cmp-navigation__item--level-0:not(:hover)>.cmp-navigation__item-link {
        color: rgba(255, 255, 255, 0.5);
    }


}