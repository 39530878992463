.txt-olympics {
    .cmp-text p {
        font-size: 1.25rem;
        line-height: 1.2;

        @media only screen and (min-width: $mobile) {
            font-size: 1.875rem;
        }
        @media only screen and (min-width: calc($tablet + 1px)) {
            font-size: 2.5rem;
        }
    }
}