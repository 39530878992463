.c-olympics-medal-home {
    .btn-olympics .cmp-button 
    {
        margin-bottom: 2rem;
        width: 100%;
        height: 72px;
        
        .cmp-button__text {
            color: #E74883;
            font-size: 25px;
        }

        .button-icon {
            stroke: #E74883;
            transition: none;
        }

        .cmp-button__text, 
        .button-icon {
            z-index: 1;
        }

        .button-background {
            z-index: 0;
        }
    }

    .btn-olympics .cmp-button:hover {
        .cmp-button__text {
            color: $white;
        }

        .button-icon, 
        .button-background path {
            stroke: $white;
        }

        .button-background path {
            fill: #E74883;
        }
    }

    &>.cmp-container::after {
        content: "";
        display: block;
        width: 100%;
        position: relative;
        background: center / cover no-repeat url(data-url:resources/images/olympics/supporters-home-mobile.svg);
        padding-top: 350px;   /* svg height */
        bottom: -2px;
        
        @media only screen and (min-width: calc($mobile + 1px)) {
            background-image: url(data-url:resources/images/olympics/supporters-home.svg); 
            background-position: bottom;
            padding-top: calc((370 / 1440) * 100%);  /* Aspect ratio padding-top: height 370, width 1440 */
        }
    }
}