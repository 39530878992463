#main-header-text {
  background-color: $red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90vh;
  max-height: calc(100% - 94px);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  background: $red;
  border-bottom: 10px solid $red;
  transform: translateY(calc(-100% - 1px));
  transition: transform 0.75s ease;

  > div {
    overflow: auto;
  }

  .hide,
  #social-links {
    display: none;
  }

  &.header-clone.is-scrolled,
  &.header-clone:hover {
    transform: translateY(calc(-100% + 40px));
  }

  &.header-clone.is-open,
  .header-clone.is-open:hover {
    transform: translateY(0);
  }

  &.is-scrolled {
    transform: translateY(calc(-100% + 0px));
  }

  &:hover {
    transform: translateY(calc(-100% + 10px));
  }

  &.header-clone.is-open .cmp-text {
    ul li ul li a {
      color: $berry;
      font-size: 1.5rem;
    }

    ul li a:hover,
    ul li ul li a:hover {
      color: $white;
    }
  }

  &:hover,
  &.is-scrolled {
    .active {
      ul {
        max-height: 100%;
        position: absolute;
        bottom: -7px;
        left: 0;
        right: 0;
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        padding-bottom: 0.438rem;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;

        &.left-aligned {
          justify-content: flex-start;
          padding-bottom: 0.125rem;
          scrollbar-color: rgba(0, 0, 0, 0.5) transparent; //firefox 
          scrollbar-width: thin; //firefox
        }

        &::-webkit-scrollbar {
          height: 4px;
          width: 0;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background: rgba(0, 0, 0, 0.5);
        }

        #main-nav > ul > li > ul li {
          margin-right: 1rem;
          border-bottom: 0;
        }

        > li {
          @media screen and (min-width: $tablet) {
            white-space: nowrap;
          }
        }
      }
    }

    .cmp-button__icon--header-logo {
      &:after {
        opacity: 0;
      }
    }

    #header-tab {
      &:hover {
        .cmp-button__icon--header-logo {
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }

  &.is-open {
    transform: translateY(0);

    a {
      color: $white;
    }

    ul:hover a {
      color: $berry;
    }

    ul > li a:hover,
    ul > li > ul li a:hover {
      color: $white;
    }

    #header-tab {
      .cmp-button__icon--header-logo {
        background-image: url("data-url:resources/images/general/icons/header-tab-cross.svg");
        width: 18px;
        height: 18px;
      }
    }

    .active {
      ul {
        display: none;

        @media only screen and (min-width: $tablet) { 
          max-height: 0;
          position: relative;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          flex-wrap: wrap;
          padding-bottom: 0;
        }
      }
      &:hover {
        @media only screen and (min-width: $tablet) {
          ul {
            max-height: 100px;
            height: auto;
            opacity: 1;
          }
        }
      }
    }

    #header-tab {
      &:hover {
        .cmp-button__icon--header-logo {
          &:after {
            opacity: 0;
          }
        }
      }
    }
    ul li > a,
    #social-links {
      display: flex;
      justify-content: center;
    }
  }

  .image {
    .cmp-adaptive-image {
      border-bottom: 2px solid $berry;
      margin-left: 1.2rem;
      margin-right: 1.2rem;

      @media only screen and (min-width: $tablet) {
        border-bottom: 0;
      }
    }

    .cmp-image__image {
      display: block;
      margin: 0 auto 1.2rem auto;
      width: 48px;
    }
  }

  #header-tab {
    background: url("data-url:resources/images/general/navigation/header-tab.svg");
    width: 61px;
    height: 72px;
    position: absolute;
    bottom: -9px;
    left: 50%;
    transform: translate(-50%, 100%);
    cursor: pointer;

    .cmp-button__icon--header-logo {
      background: url("resources/images/general/icons/innocent-logo.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      display: block;
      margin: 0 auto;
      width: 30px;
      height: 40px;
      position: relative;

      &:after {
        content: "";
        background-image: url("data-url:resources/images/general/icons/menu.svg");
        background-repeat: no-repeat;
        width: 38px;
        height: 27px;
        display: block;
        position: absolute;
        bottom: -32px;
        left: 19px;
        transition: opacity 0.5s ease;
      }
    }
  }
}

#social-links {
  display: flex;
  justify-content: center;
  margin-top: 1.625rem;

  .button {
    width: auto;

    &:not(:last-child) {
      margin-right: 1.5rem;
    }
  }

  .cmp-button__icon {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 30px;
    height: 30px;
    display: block;
    transition: background-image 0.5s ease;
  }

  &:hover {
    .cmp-button__icon--facebook {
      background-image: url("data-url:resources/images/general/icons/facebook-red.svg");
    }

    .cmp-button__icon--instagram {
      background-image: url("data-url:resources/images/general/icons/instagram-red.svg");
    }

    .cmp-button__icon--x-social {
      background-image: url("data-url:resources/images/general/icons/x-social-red.svg");
    }

    .cmp-button__icon--tiktok {
      background-image: url("data-url:resources/images/general/icons/tik-tok-icon-red.svg");
    }
  }

  .cmp-button__icon--facebook {
    background-image: url("data-url:resources/images/general/icons/facebook-white.svg");

    &:hover {
      background-image: url("data-url:resources/images/general/icons/facebook-white.svg");
    }
  }

  .cmp-button__icon--instagram {
    background-image: url("data-url:resources/images/general/icons/instagram-white.svg");

    &:hover {
      background-image: url("data-url:resources/images/general/icons/instagram-white.svg");
    }
  }

  .cmp-button__icon--x-social {
    background-image: url("data-url:resources/images/general/icons/x-social.svg");

    &:hover {
      background-image: url("data-url:resources/images/general/icons/x-social.svg");
    }
  }

  .cmp-button__icon--tiktok {
    background-image: url("data-url:resources/images/general/icons/tik-tok-icon.svg");

    &:hover {
      background-image: url("data-url:resources/images/general/icons/tik-tok-icon.svg");
    }
  }
}

#main-nav {
  text-align: center;
  overflow: auto;

  &:hover {
    ul > li:hover {
      > a {
        color: $white;
      }
    }

    ul > li:not(:hover) {
      > a {
        color: $berry;
      }
    }
  }

  a {
    @include font-innocent-bold;
    transition: color 0.5s ease;
    color: $white;
    text-decoration: none;
  }

  ul > li {
    line-height: 2;
    margin-left: 1.2rem;
    margin-right: 1.2rem;
    border-bottom: 2px solid $berry;

    @media only screen and (min-width: $tablet) {
      border-bottom: 0;
      margin-bottom: 0;
    }

    &.hide {
      display: none;
    }

    &.is-open {
      background-color: $red;
      margin-bottom: 0;
      border-bottom: 0;

      > a {
        display: none;
      }

      .navigation-icon {
        position: relative;
        background-image: url("data-url:resources/images/general/icons/icon-header-arrow-left.svg");
      }

      ul {
        li {
          border-bottom: 2px solid $berry;
          margin-right: 0;
          margin-left: 0;
        }
      }
    }

    ul {
      display: none;
    }

    @media only screen and (min-width: $tablet) {
      ul {
        max-height: 0;
        transition: max-height 0.75s ease;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        overflow: hidden;
      }

      &:hover {
        ul {
          max-height: 116px;
          opacity: 1;
        }
      }
    }

    > a {
      font-size: 2.375rem;
      @media only screen and (max-width: $tablet) {
        font-size: 1.5rem;
      }
    }
  }

  ul > li.is-open.active ul,
  ul > li.is-open ul {
    display: block;
    position: relative;
    padding: 0;
    bottom: 0;
    overflow: hidden;
  }

  li.active > ul {
    li {
      line-height: 1;
      @media only screen and (max-width: $tablet) {
        border-bottom: none;
        flex: none;
      }
    }
  }

  ul li > ul li {
    @media only screen and (min-width: $tablet) {
      margin-right: 0.7rem;
      margin-left: 0.7rem;
      border-bottom: 0;
    }

    > a {
      font-size: 1.5rem;
      line-height: 1.2;

      @media only screen and (max-width: $mobile) {
      padding: 8px 0;
    }

      &:hover {
        color: $white;
      }
    }
  }

  .navigation-icon {
    display: block;
    background-image: url("data-url:resources/images/general/icons/icon-header-plus.svg");
    width: 35px;
    height: 20px;
    margin: 10px auto;
    background-position: 50%;
    background-repeat: no-repeat;
    cursor: pointer;

    @media only screen and (min-width: $tablet) {
      display: none;
    }
  }
}