.c-kids-teaser-slider {
    @each $key,$val in $kids-colors { 
        .t-kids-potato-#{$key} {
            svg path {
                fill: #{$val};
            }
        }
    }

    .swiper-wrapper {
        padding-top: 110px;
    }

    .cmp-teaser {
        display: flex;
        flex-direction: column-reverse;
        max-width: 420px;
        padding: 0 30px;
        margin: 0 auto;
        position: relative;
        @media only screen and (min-width: $laptop) {
            max-width: 520px;
            padding: 0;
        }
    }
    .cmp-teaser__pretitle {
        position: absolute;
        @include font-innocent-super-scrawl;
        line-height: 1.1;
        font-size: 30px;
        max-width: 270px;
        top: -110px;
        right: 40px;
        transform: rotate(9.27deg);
        height: 180px;
        display: flex;
        @media only screen and (min-width: $laptop) {
            max-width: 320px;
            top: -80px;
            right: -130px;  
        }
        &:before {
            content: "";
            display: block;
            width: 211px;
            height: 110px;
            transform: rotate(-35deg);
            margin-top: auto;
            background-image: url('data-url:resources/images/kids/icons/arrow-swiper-item.svg');
            @media only screen and (min-width: $laptop) {
                width: 161px;
                transform: rotate(0);
            }
        }
    }

    .cmp-teaser__title {
        font-size: 28px;
    }

    .cmp-teaser__description p {
        @include font-innocent-light;
        font-size: 22px;
        line-height: 32px;
        margin-top: 40px;
        @media only screen and (min-width: $laptop) {
            margin-top: 20px;
        }
    }

    .cmp-teaser__content {
        max-width: 420px;
        margin: 30px auto 0 auto;
        display: none;
        @media only screen and (min-width: $laptop) {
            margin: 50px auto 0 auto;
        }
    }

    .cmp-teaser__image {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 400px;
        @media only screen and (min-width: $laptop) {
            height: 477px;
        }
        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
            max-width: 100%;
        }

        .cmp-image {
            margin-top: 0;
        }

        .cmp-image__image {
            margin: 0 auto;
            max-width: 350px;
            @media only screen and (min-width: $laptop) {
                max-width: 510px;
            }
        }
    }

    .swiper-slide-active {
        .cmp-teaser__content {
            display: block;
        }
    }
       

   .swiper-pagination {
    display: flex;
    justify-content: center;
    order: 1;

    .swiper-pagination-bullet {
        width: 7px;
        height: 7px;
        border: 1px solid map-get($colors, sharpieBlack);
        display: block;
        border-radius: 50%;
        margin: 0 3px;

        &.swiper-pagination-bullet-active {
            background-color: map-get($colors, sharpieBlack);
        }
    }
   }

   .swiper-navigation {
        margin: 80px auto 0 auto;
        z-index: 2;
        display: flex;
        justify-content: space-between;
        position: absolute;
        left: 20px;
        right: 20px;
        max-width: 470px;

        @media only screen and (min-width: $tablet) {
            position: absolute;
            top: -190px;
            width: 580px;
            max-width: initial;
            left: 50%;
            margin-left: -290px;
        }

        @media only screen and (min-width: $laptop) {
            margin-top: 180px;
        }
   }

   .swiper-button-next,
   .swiper-button-prev {
        display: block;
        width: 43px;
        height: 45px;
        position: relative;
        cursor: pointer;
        margin: 0;
   }

   .swiper-button-next {
        background-image: url('data-url:resources/images/kids/icons/arrow-next.svg');
    }

    .swiper-button-prev {
        background-image: url('data-url:resources/images/kids/icons/arrow-prev.svg');
    }
}