.c-olympics-slider {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;

    .teaser {
        display: flex;
        align-items: center;
        justify-content: space-around;

        .cmp-teaser {
            width: 420px;
            display: flex;
            flex-direction: column-reverse;
            position: relative;
            padding-top: 20px;

            h2 {
                font-size: 1.5rem;
                line-height: 1.2;
                color: map-get($colors, dark-pink) ;
                text-align: center;
                margin-top: 20px;
        
                @media only screen and (max-width: $mobile) {
                    font-size: 1.25rem;
                }
            }

            &__image {
                margin: 0 auto;
                width: revert-layer;
                max-width: 200px;
        
                @media only screen and (min-width: $mobile) {
                    max-width: 205px;
                }
            }
    
            &__content {
                @media only screen and (min-width: $tablet) {
                    margin: 0;
                }
    
                @media only screen and (max-width: $tablet) {
                    display: none;
                }
            }
    
            &__description {
                p {
                    font-size: 20px;
                    margin-bottom: 10px;
    
                    @media only screen and (max-width: $mobile) {
                        font-size: 18px;
                    }
    
                    @media only screen and (min-width: 301px) and (max-width: 430px) {
                        margin: 0 10px 5px;
                    }
    
                    b {
                        @include font-innocent-bold;
                        font-weight: 300;
                    }
                }
            }
    
        }

        &.flyout-top-left {
            .cmp-teaser__pretitle {
                position: absolute;
                top: 0;
                right: -15px;
                left: unset;
                font-size: 25px;
                transform: rotate(10deg);
                width: 110px;
    
                @media only screen and (min-width: calc($tablet + 1px)) {
                    top: 20px;
                    right: -55px;
                    width: 120px;
                }
    
                @media only screen and (min-width: calc($laptop + 1px)) {
                    right: -45px;
                }
    
                &::after {
                    content: "";
                    display: block;
                    width: 100px;
                    height: 110px;
                    background-image: url('data-url:resources/images/olympics/arrow-flyout-left-mobile.svg');
                    background-repeat: no-repeat;
                    position: absolute;
                    top: 65px;
                    left: 30%;
                    transform: rotate(-10deg);
    
                    @media only screen and (min-width: calc($tablet + 1px)) {
                        background-image: url('data-url:resources/images/olympics/arrow-flyout-left.svg');
                        background-repeat: no-repeat;
                    }
                }
            }
        }
    }

    .swiper-slide-active {
        .cmp-teaser__content {
            @media screen and (max-width: $tablet) {
                display: block;
            }
        }

        .cmp-teaser__image {
            @media screen and (min-width: $mobile) {
                transform: scale(1.1);
            }
        }
    }

    .swiper-pagination {
        display: flex;
        justify-content: center;
        order: 1;
        margin-top: 10px;

        .swiper-pagination-bullet {
            background-image: url("data-url:resources/images/general/icons/olympics-dot-icon.svg");
            width: 9px;
            display: block;
            margin: 0 3px;
            margin-top: 1px;

            @media screen and (min-width: $mobile) {
                background-image: url("data-url:resources/images/general/icons/olympics-dot-icon.svg");
                width: 20px;
                background-size: 40px;
            }

            &.swiper-pagination-bullet-active {
                background-image: url("data-url:resources/images/general/icons/olympics-dot-icon-activeM.svg");
                width: 15px;
                height: 15px;
                background-size: 25px;
                margin-top: 0;

                @media screen and (min-width: $mobile) {
                    background-image: url("data-url:resources/images/general/icons/olympics-dot-icon-active.svg");
                    width: 20px;
                    height: 20px;
                    background-size: 42px;
                }
            }
        }
    }

    .swiper-navigation {
        width: 100%;
        z-index: 2;
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 95px;

        .swiper-button-next,
        .swiper-button-prev {
            display: flex;
            z-index: 2;
            width: 48px;
            height: 50px;
            position: absolute;
            cursor: pointer;
        }
    
        .swiper-button-next {
            background-image: url("data-url:resources/images/general/icons/olympics-arrow-next.svg");
            right: 0;
    
            @media screen and (min-width:$mobile) and (max-width: $tablet) {
                right: 150px;
            }
        }

        .swiper-button-prev {
            background-image: url("data-url:resources/images/general/icons/olympics-arrow-prev.svg");
            left: 0;
    
            @media screen and (min-width:$mobile) and (max-width: $tablet) {
                left: 150px;
            }
        }
    }

    .swiper-pagination.swiper-pagination-lock,
    .swiper-navigation .swiper-button-lock {
        display: none;
    }
}