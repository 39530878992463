.c-fluid {
 > .cmp-container {
    max-width: 1210px;
    width: 100%;
    margin-inline: auto;
    padding-inline: 15px;
  }
}

.c-flex-center {
  text-align: center;

  > .cmp-container {
    @media only screen and (min-width: $mobile) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    
    .btn-default {
      margin: 12px 6px;
    }
  }
}
