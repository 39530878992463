.flyout-top-right {
    text-align: center;

    .cmp-text {
        position: relative;
    }

    b {
        display: grid;
        grid-template-columns: 100px auto;
        @include font-innocent-super-scrawl;
        font-size: 30px;
        text-align: center;
        max-width: 340px;
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: rotate(9.27deg);
        height: 180px;
        line-height: 1.1;

        @media only screen and (max-width: $mobile) {
            left: 20%;
            right: 0;
            height: 160px;
            max-width: 280px;
        }

        &:before {
            content: "";
            display: block;
            width: 100px;
            height: 110px;
            margin-top: auto;
            background-image: url('data-url:resources/images/kids/icons/arrow-swiper-item.svg');
            background-repeat: no-repeat;

            @media only screen and (min-width: $laptop) {
                transform: rotate(0);
            }
        }
    }
}