.xf-header-olympics {
    #main-header-text {
        background: $dark-pink;
        border-bottom: 10px solid $dark-pink;
        background-color: $dark-pink;

        #header-tab {
            width: 61px;
            height: 71px;
            background: url('data-url:resources/images/general/icons/olympics-navigation.svg'); 
        }

        #header-tab .cmp-button__icon--header-logo:after {
           background-image: url('data-url:resources/images/olympics/olympics-menu.svg');
        }

        .image {
            .cmp-adaptive-image {
                border-bottom: 2px solid $light-pink;
                margin-left: 1.2rem;
                margin-right: 1.2rem;

                @media only screen and (min-width: $tablet) {
                    border-bottom: 0;
                }
            }

            .cmp-image__image {
                display: block;
                margin: 0 auto 1.2rem auto;
                width: 48px;
            }

        }

        &.is-open {

            ul>li a:hover,
            ul>li>ul li a:hover {
                color: $white;
            }
        }
    }

    #main-header-text.is-scrolled {
        background-color: $dark-pink;
    }


    #social-links:hover {
        .cmp-button__icon--facebook {
            background-image: url("data-url:resources/images/kids/icons/facebook-light-black.svg");
        }

        .cmp-button__icon--instagram {
            background-image: url("data-url:resources/images/kids/icons/instagram-light-black.svg");
        }

        .cmp-button__icon--x-social {
            background-image: url("data-url:resources/images/kids/icons/x-social-light-black.svg");
        }
    }

    #social-links {
        .cmp-button__icon--facebook {
            background-image: url("data-url:resources/images/kids/icons/facebook.svg");

            &:hover {
                background-image: url("data-url:resources/images/kids/icons/facebook.svg");
            }
        }

        .cmp-button__icon--instagram {
            background-image: url("data-url:resources/images/kids/icons/instagram.svg");

            &:hover {
                background-image: url("data-url:resources/images/kids/icons/instagram.svg");
            }
        }

        .cmp-button__icon--x-social {
            background-image: url("data-url:resources/images/kids/icons/x-social-white.svg");

            &:hover {
                background-image: url("data-url:resources/images/kids/icons/x-social-white.svg");
            }
        }
    }


    #main-nav {

        ul>li {
            &.is-open {
                background-color: $dark-pink;
                border-bottom: 0;

                >a {
                    color: $light-pink;
                }

                ul>li {
                    border-bottom: 2px solid $light-pink;

                    >a {
                        color: $light-pink;
                    }
                }
            }

            @media only screen and (max-width: $tablet) {
                border-bottom: 2px solid $light-pink;
            }
        }

        &:hover {
            ul>li:not(:hover) {
                >a {
                    color: $light-pink;
                }
            }
        }
    }
}

.xf-header-olympics.olympics-medal {
    #main-header-text {
        background: #ae2573;
        border-bottom: 10px solid #ae2573;

        #header-tab {
            width: 61px;
            height: 71px;
            background: center / contain no-repeat url('data-url:resources/images/general/icons/olympics-medal-navigation.svg');
        }

        #header-tab .cmp-button__icon--header-logo:after {
            background-image: url('data-url:resources/images/general/icons/olympics-medal-menu.svg');
            bottom: -29px;
            left: 25px;
        }
    }

    #main-header-text.is-scrolled {
        background-color: #ae2573;
    }

    #main-nav ul>li.is-open {
        background-color: #ae2573;
    }
}