$teaser-list-colors: (
  "almond": $almond,
  "apple": $apple,
  "berry": $berry,
  "broccoli": $broccoli,
  "grape": $grape,
  "orange": $orange,
  "mango": $mango,
  "red": $red,
  "spinach": $spinach,
  "water": $water,
  "white": $white,
);

@each $key, $val in $teaser-list-colors {
  .c-teaser-list .t-scheme-#{$key} {
    .cmp-teaser__title:before,
    .cmp-teaser__description-underline:before {
      background-image: url("data-url:resources/images/general/icons/icon-bullet-#{$key}.svg");
    }

    .cmp-teaser__title svg {
      display: block;
      path {
        stroke: #{$val};
      }
    }

    .cmp-teaser__description-underline svg {
      path {
        stroke: #{$val};
      }
    }
  }
}

.c-teaser-list {
  position: relative;

  > .cmp-container {
        counter-reset: teaser-list-number;
        max-width: 1210px;
        width: 100%;
        margin-inline: auto;
        padding-inline: 15px;
    }

  ul,
  ol {
    list-style-position: inside;

    li {
      @include font-innocent-light;
    }
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  .teaser {
    text-align: left;
    @include teaser-common;
    @include teaser-image-right;
    @include teaser-mask-curvy;

    .cmp-teaser__content {
      padding-left: 70px;
      position: relative;
    }

    .cmp-teaser__description-underline {
      display: flex;
      flex-direction: column;

      svg {
        order: -1;
        display: block;
        max-width: 100%;

        path {
          pointer-events: none;
          stroke-dashoffset: inherit !important;
        }
      }
    }

    .cmp-teaser__title svg {
      display: block;

      path {
        pointer-events: none;
        stroke-dashoffset: inherit !important;
      }
    }

    .cmp-teaser {
      padding: 80px 0;

      .cmp-teaser__title {
        margin: 0;
        color: $black;
      }

      .cmp-teaser__description-underline {
        margin-bottom: 20px;
      }

      .cmp-teaser__image {
        svg {
           @media only screen and (min-width: $tablet) {
             max-width: 380px;
           }
        }
      }

      .cmp-image {
        max-width: 350px;
        margin: auto;
      }

      .cmp-teaser__title:before,
      .cmp-teaser__description-underline:before {
        counter-increment: teaser-list-number;
        content: counter(teaser-list-number) " ";
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 44px;
        position: absolute;
        top: 0;
        left: 0;
        color: $white;
        font-size: 22px;

        @media only screen and (max-width: $mobile) {
          font-size: 15px;
        }
      }
    }
  }
}
