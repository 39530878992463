.c-kids-teaser-list {
  position: relative;

  .cmp-container {
    max-width: 1210px;
    margin-left: auto;
    margin-right: auto;
    counter-reset: section;

    @media only screen and (min-width: $mobile) and (max-width: $laptop) {
      padding: 0 15px;
    }
  }

  .teaser {
    margin-bottom: 100px;

    @media only screen and (max-width: $mobile) {
      margin-bottom: 60px;
    }
    
    &:nth-child(odd) {
      .cmp-teaser {
        @media only screen and (min-width: $mobile) {
          flex-direction: row-reverse;
        }
      }

      .cmp-teaser__content {
        @media only screen and (min-width: $tablet) {
          padding-right: 0;
          padding-left: 100px;
        }
      }
    }

    picture {
      -webkit-mask: url(data-url:resources/images/kids/icons/teaser-common-mask.svg) center / contain no-repeat;
      object-fit: cover;
      padding-bottom: 100%;
      overflow: hidden;
      position: relative;
      display: block;
      max-width: 100%;
      margin: 0 auto;
  
      @media only screen and (min-width: 630px) and (max-width: 766px) {
        max-width: 70%;
      }
  
      @media only screen and (min-width: $laptop) {
        max-width: 95%;
      }
    }

    .cmp-teaser__image {
      display: grid;
    }
  
    .cmp-adaptive-image {
      grid-row: 1;
      grid-column: 1;
      width: calc(100% - 100px);
      margin: auto;
    }
  
    .cmp-image__image {
      width: 100%;
      position: absolute;
      top: 50%;
      right: 0;
      bottom: 0;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      border-radius: 20px;
    }
  
    .cmp-teaser__image {
      position: relative;
      text-align: center;
  
      @media only screen and (max-width: $mobile) {
        margin-bottom: 2rem;
      }
  
      svg {
        grid-column: 1;
        grid-row: 1;
        margin: auto;
        height: auto;
        max-width: 100%;
  
        @media only screen and (max-width: $mobile) {
          transform: rotate(-70deg);
          position: relative;
          z-index: -1;
        }
  
        @media only screen and (max-width: 440px) {
          max-width: 80%;
        }
      }
    }
  }

  .cmp-teaser {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media only screen and (max-width: calc($mobile - 1px)) {
      flex-direction: column-reverse;
      align-items: inherit;
    }
  }

  .cmp-teaser__content,
  .cmp-teaser__image {
    padding: 0 15px;
    flex: 0 0 100%;
    max-width: 100%;

    @media only screen and (min-width: $mobile) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

 

  .cmp-teaser {

    padding-top: 70px;
    @media only screen and (max-width: $mobile) {
      padding: 0 20px;
      text-align: left;
    }
  }

  .cmp-teaser__content {
    color: $black;
    text-align: left;

    @media only screen and (min-width: $tablet) {
      padding-right: 100px;
    }

    .cmp-teaser__title {
      position: relative;
      padding: 0 0 0 55px;
      @include font-innocent-bold;
      font-size: 28px;
      line-height: 37px;
      margin-bottom: 30px;
    }

    .cmp-teaser__title:before {
      counter-increment: section;
      content: counter(section) " ";
      background-image: url("data-url:resources/images/kids/icons/potato-bg-kids.svg");
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      left: 0;
      width: 41px;
      height: 37px;
      line-height: 37px;
      @include font-innocent-bold;
      text-align: center;
      font-size: 22px;
    }

    .cmp-teaser__description {
      @media only screen and (max-width: $mobile) {
        padding-left: 55px;
      }

      p {
        @include font-innocent-light;
        font-size: 22px;
        line-height: 32px;
      }
    }
  }

  .adaptiveImage {
    display: none;
    @media only screen and (min-width: 1500px) {
      display: block;
      width: 200px;
      height: auto;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

@each $key,
$val in $kids-colors {
  .t-kids-potato-#{$key} {
    .cmp-teaser__image {
      svg path {
        fill: #{$val};
      }
    }
  }
}