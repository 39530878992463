@each $key,$val in $colors { 
    .c-top-wave-#{$key} {
        > .cmp-container > .top-wave {
            background: #{$val};
        }
    }

    .c-bottom-wave-#{$key} {
        > .cmp-container > .bottom-wave {
            fill: #{$val};
        }
    }
}

[class*='c-top-wave'],
[class*='c-bottom-wave'] {
    & > .cmp-container {
        position: relative;
        overflow: hidden;
    }

    .top-wave, .bottom-wave {
        animation: wave 16s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
        transform: translate3d(0, 0, 0);
        position: absolute;
        overflow: hidden;
        width: 200vw;
        display: block; 
        height: 20px;
        @media only screen and (min-width: $mobile) {
            height: 40px;
        }
    }
}

.bottom-wave {
    bottom: -1px;
}

.top-wave {
    top: -1px;
}

@keyframes wave {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100vw); 
    }
  }