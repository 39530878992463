$text-list-colors: (
  "water": $water,
  "almond": $almond,
  "berry": $berry,
  "broccoli": $broccoli,
  "red": $red,
  "grape": $grape,
  "mango": $mango,
  "white": $white,
);

@each $key,
$val in $text-list-colors {
  .txt-list-#{$key} h2 svg, 
  .txt-list-#{$key} h3 svg, 
  .txt-list-#{$key} h4 svg {
    stroke: #{$val};
    display: block;
    pointer-events: none;
  }

  .txt-list-#{$key} ol>li:before, 
  .txt-list-#{$key} h2:before, 
  .txt-list-#{$key} h3:before, 
  .txt-list-#{$key} h4:before {
    background-image: url("data-url:resources/images/general/icons/icon-bullet-#{$key}.svg");
  }
}

.txt-list {

  .cmp-text {
    @media only screen and (min-width: $mobile) {
      padding-left: 30px;
    }
  }

  ul {
    list-style: initial;
  }

  ul,
  ol {
    list-style-position: inside;
    padding: 0;
    li {
      margin-bottom: 5px;
    }
  }

  h2,
  h3,
  h4 {
    color: $black;
    font-size: 1.75rem;
    line-height: 2.188rem;
    margin: 0;
    display: inline-block;
    @include font-innocent-bold;
    position: relative;
    padding-left: 60px;

    @media only screen and (max-width: $mobile) {
      font-size: 1.5rem;
      line-height: 1.625rem;
    }
  }

  h2:before,
  h3:before,
  h4:before {
    font-size: 1.375rem;
    line-height: 1.938rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    color: $white;
    @include font-innocent-bold;
    position: absolute;
    left: 0;

    @media only screen and (max-width: $tablet) {
      font-size: 0.938rem;
      line-height: 1.438rem;
    }
  }

  h2 {
    counter-increment: h2-bullet-number-counter;
    &:before {
      content: counter(h2-bullet-number-counter);
    }
  }

  h3 {
    counter-increment: h3-bullet-number-counter;
    &:before {
      content: counter(h3-bullet-number-counter);
    }
  }

  h4 {
    counter-increment: h4-bullet-number-counter;
    &:before {
      content: counter(h4-bullet-number-counter);
    }
  }

  .o-underline {
    display: inline-block;
    svg {
      path {
        stroke-dasharray: 0 !important;
      }
    }
  }

  p, li {
    color: $black;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 100;
    margin: 0 0 30px 65px; 
    position: relative;
    @include font-innocent-light;

    @media only screen and (max-width: $mobile) {
      font-size: 1.125rem;
      line-height: 1.75rem;
    }
  }

  p a {
    color: $black;
    @include font-innocent-bold;
  }
}