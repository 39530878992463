.txt-signage {
  .cmp-text {
    background-image: url("data-url:resources/images/kids/icons/entry-page-brown-bg.svg");
    width: 280px;
    height: 165px;
    z-index: 1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    bottom: 40px;
    left: 0; 

    @media only screen and (max-width: $tablet) {
      height: 165px;
      bottom: -40px;
      left: -10px;
    }

    @media only screen and (max-width: $mobile) {
      width: 180px;
      height: 110px;
      bottom: -27px;
      left: -16px;
    }
  }

  p {
    @include font-innocent-bold;
    text-align: center;
    width: 135px;
    max-height: 130px;
    font-size: 30px;
    line-height: 1.1;
    color: white;
    position: absolute;
    left: 100px;
    top: 47%;
    transform: translateY(-50%) rotate(-9.63deg);

    @media only screen and (max-width: $mobile) {
      font-size: 20px;
      width: 100px;
      left: 60px;
    }
  }
}
