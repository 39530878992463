@each $key,$val in $colors {
  .title-color-#{$key} {
    .cmp-title__text {
      color: #{$val}; 
    }
    .cmp-title__link {
      color: #{$val}; 
    }
  }
}

