#main-header {
    background-color: $red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 90vh;
    max-height: calc(100% - 94px);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;
    background: $red;
    border-bottom: 10px solid $red;
    transform: translateY(calc(-100% - 1px));
    transition: transform 0.75s ease;

    >div {
        overflow: auto;
    }

    .cmp-navigation__item--level-0>.cmp-navigation__item-link,
    #social-links {
        display: none;
    }

    &.header-clone.is-scrolled,
    &.header-clone:hover {
        transform: translateY(calc(-100% + 40px));
    }

    &.header-clone.is-open,
    .header-clone.is-open:hover {
        transform: translateY(0);
    }

    &.is-scrolled {
        transform: translateY(calc(-100% + 0px));
    }

    &:hover {
        transform: translateY(calc(-100% + 10px));
    }


    &.header-clone nav ul li {
        ul:hover a {
            color: $berry;
        }

        ul li a {
            color: $white;
        }
    }

    &.header-clone.is-open nav {
        ul li ul li a {
            color: $berry;
        }

        ul li a:hover,
        ul li ul li a:hover {
            color: $white;
        }
    }

    &:hover,
    &.is-scrolled {
        .cmp-navigation__item--active {
            .cmp-navigation__group {
                max-height: 100%;
                position: absolute;
                bottom: -10px;
                left: 0;
                right: 0;
                display: flex;
                overflow: auto;
                padding: 0 20px;

                .cmp-navigation__item--level-1 {
                    margin-right: 1rem;
                    border-bottom: 0;
                }
            }
        }

        .cmp-button__icon--header-logo {
            &:after {
                opacity: 0;
            }
        }

        #header-tab {
            &:hover {
                .cmp-button__icon--header-logo {
                    &:after {
                        opacity: 1;
                    }
                }
            }
        }
    }


    &.is-open {
        transform: translateY(0);

        .cmp-navigation__item-link {
            color: $white;
        }

        ul:hover a {
            color: $berry;
        }

        .cmp-navigation__group .cmp-navigation__item--level-0 a:hover,
        .cmp-navigation__item--level-1 a:hover {
            color: $white;
        }

        #header-tab {
            .cmp-button__icon--header-logo {
                background-image: url("data-url:resources/images/general/icons/header-tab-cross.svg");
                width: 18px;
                height: 18px;
            }
        }

        .cmp-navigation__item--active {
            .cmp-navigation__group {
                display: none;

                @media only screen and (min-width: $tablet) {
                    max-height: 0;
                    position: relative;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    display: flex;
                }
            }
        }

        #header-tab {
            &:hover {
                .cmp-button__icon--header-logo {
                    &:after {
                        opacity: 0;
                    }
                }
            }
        }

        .cmp-navigation__item--level-0>.cmp-navigation__item-link,
        #social-links {
            display: flex;
            justify-content: center;
        }
    }

    .image {
        .cmp-adaptive-image {
            border-bottom: 2px solid $berry;
            margin-left: 1.2rem;
            margin-right: 1.2rem;

            @media only screen and (min-width: $tablet) {
                border-bottom: 0;
            }
        }

        .cmp-image__image {
            display: block;
            margin: 0 auto 1.2rem auto;
            width: 48px;
        }

    }

    #header-tab {
        background: url('data-url:resources/images/general/navigation/header-tab.svg');
        width: 61px;
        height: 72px;
        position: absolute;
        bottom: -9px;
        left: 50%;
        transform: translate(-50%, 100%);
        cursor: pointer;

        .cmp-button__icon--header-logo {
            background: url('resources/images/general/icons/innocent-logo.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            display: block;
            margin: 0 auto;
            width: 30px;
            height: 40px;
            position: relative;

            &:after {
                content: "";
                background-image: url('data-url:resources/images/general/icons/menu.svg');
                background-repeat: no-repeat;
                width: 38px;
                height: 27px;
                display: block;
                position: absolute;
                bottom: -32px;
                left: 19px;
                transition: opacity 0.5s ease;
            }
        }

    }
}

#social-links {
    // > div {
    display: flex;
    justify-content: center;
    margin-top: 1.625rem;

    .button {
        width: auto;

        &:not(:last-child) {
            margin-right: 1.5rem;
        }
    }

    // }
    .cmp-button__icon {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 30px;
        height: 30px;
        display: block;
        transition: background-image 0.5s ease;
    }

    &:hover {
        .cmp-button__icon--facebook {
            background-image: url("data-url:resources/images/general/icons/facebook-red.svg");
        }

        .cmp-button__icon--instagram {
            background-image: url("data-url:resources/images/general/icons/instagram-red.svg");

        }

        .cmp-button__icon--x-social {
            background-image: url("data-url:resources/images/general/icons/x-social-red.svg");

        }

        .cmp-button__icon--tiktok {
            background-image: url("data-url:resources/images/general/icons/tik-tok-icon-red.svg");

        }
    }

    .cmp-button__icon--facebook {
        background-image: url("data-url:resources/images/general/icons/facebook-white.svg");

        &:hover {
            background-image: url("data-url:resources/images/general/icons/facebook-white.svg");
        }
    }

    .cmp-button__icon--instagram {
        background-image: url("data-url:resources/images/general/icons/instagram-white.svg");

        &:hover {
            background-image: url("data-url:resources/images/general/icons/instagram-white.svg");
        }
    }

    .cmp-button__icon--x-social {
        background-image: url("data-url:resources/images/general/icons/x-social.svg");

        &:hover {
            background-image: url("data-url:resources/images/general/icons/x-social.svg");
        }
    }

    .cmp-button__icon--tiktok {
        background-image: url("data-url:resources/images/general/icons/tik-tok-icon.svg");

        &:hover {
            background-image: url("data-url:resources/images/general/icons/tik-tok-icon.svg");
        }
    }
}

#main-navigation {
    text-align: center;
    overflow: auto;

    &:hover {
        .cmp-navigation__item--level-0 {
            >.cmp-navigation__item-link {
                color: $white;
            }
        }

        .cmp-navigation__item--level-0:not(:hover) {
            >.cmp-navigation__item-link {
                color: $berry;
            }
        }
    }

    .cmp-navigation__item-link {
        @include font-innocent-bold;
        transition: color 0.5s ease;
        color: $white;
    }

    .cmp-navigation__item--level-0 {
        line-height: 2;
        margin-left: 1.2rem;
        margin-right: 1.2rem;
        border-bottom: 2px solid $berry;

        @media only screen and (min-width: $tablet) {
            border-bottom: 0;
            margin-bottom: 0;
        }

        &.hide {
            display: none;
        }

        &.is-open {
            background-color: $red;
            margin-bottom: 0;
            border-bottom: 0;

            >.cmp-navigation__item-link {
                display: none;
            }

            .navigation-icon-plus {
                position: relative;
                background-image: url('data-url:resources/images/general/icons/icon-header-arrow-left.svg');
            }

            .cmp-navigation__group {
                display: block;
                position: relative;
                padding: 0;
                bottom: 0;

                .cmp-navigation__item--level-1 {
                    border-bottom: 2px solid $berry;
                    margin-right: 0;
                }
            }
        }

        .cmp-navigation__group {
            display: none;
        }

        @media only screen and (min-width: $tablet) {

            .cmp-navigation__group {
                max-height: 0;
                overflow: hidden;
                transition: max-height 0.75s ease;
                display: flex;
                justify-content: center;
            }

            &:hover {
                .cmp-navigation__group {
                    max-height: 54px;
                    opacity: 1;
                    display: flex;
                }
            }
        }

        >.cmp-navigation__item-link {
            font-size: 2.375rem;

            @media only screen and (max-width: $tablet) {
                font-size: 1.5rem;
            }
        }
    }

    .cmp-navigation__item--level-1 {
        border-bottom: 2px solid $berry;

        @media only screen and (min-width: $tablet) {
            margin-right: 0.7rem;
            margin-left: 0.7rem;
            border-bottom: 0;
        }

        >.cmp-navigation__item-link {
            font-size: 1.5rem;
            white-space: nowrap;

            &:hover {
                color: $white;
            }
        }
    }

    .navigation-icon-plus {
        display: block;
        background-image: url('data-url:resources/images/general/icons/icon-header-plus.svg');
        width: 35px;
        height: 20px;
        margin: 10px auto;
        background-position: 50%;
        background-repeat: no-repeat;
        cursor: pointer;

        @media only screen and (min-width: $tablet) {
            display: none;
        }
    }
}