
//== Defaults

html, body {
  margin: 0;
  // @include font-innocent-demi;
  @include font-innocent-bold;
}

a {
  text-decoration: none;
}

p {
  margin-block: 0.75rem;
  line-height: 28px;
}

button {
  border: none;
  background: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block: 0;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

*, ::after, ::before {
  box-sizing: border-box;
}

img {
  width: 100%;
  height: auto;
}
//== Grid

$max_col: 12;

// Default breakpoint
.aem-Grid {
  @include generate-grid(default, $max_col);
}

// Tablet breakpoint
@media (max-width: $tablet) {
  .aem-Grid {
    @include generate-grid(tablet, $max_col);
  }
}

// Phone breakpoint
@media (max-width: $mobile) {
  .aem-Grid {
    @include generate-grid(phone, $max_col);
  }
}

.grecaptcha-badge {
  display: none !important;
}