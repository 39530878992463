.ai-olympics-top-right {
    position: relative;

    .cmp-image__image {
        position: absolute;
        width: 200px;
        top: -8px;
        right: -40px;

        @media only screen and (min-width: $mobile) and (max-width: $tablet) {
            width: 300px;
            top: 0;
            right: -30px;
        }

        @media only screen and (min-width: calc($tablet + 1px)) {
            width: 455px;
            top: 0;
            right: -60px;
        }
    }
}

.c-fluid:has(.ai-olympics-top-right) {
    >.cmp-container {
        overflow: hidden;
    }
}