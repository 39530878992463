//Kids footer variables
$font-size-footer: 20px;
$bg-color-footer: #ECF6DF;
$font-size-mobile: 18px;
$line-height-footer: 24px;
$bg-last-section-footer: #a2d45e;

.xf-footer-kids .cmp-experiencefragment--footer {
  position: relative;

  >.cmp-container {
    background-image: url(data-url:resources/images/kids/icons/footer-top-slide.svg);
    background-size: cover;
    background-color: transparent;
    margin-top: 160px;
    padding-top: 140px;

    @media only screen and (max-width: $mobile) {
      padding-top: 100px;
    }

    >.adaptiveImage {
      z-index: -1;
      width: 210px;
      position: absolute;
      top: -60px;
      left: 0;
      transform: rotate(.38deg);

      @media only screen and (max-width: $mobile) {
        display: none;
      }
    }

    #back-to-top {
      display: flex;
      justify-content: center;
      position: absolute;
      left: 50%;
      top: -130px;
      transform: translateX(-50%);
      cursor: pointer;

      @media only screen and (max-width: $mobile) {
        top: -140px;
      }

      .text {
        display: flex;
        align-items: center;
        text-align: center;
        max-width: 160px;

        &:nth-of-type(1) {
          margin-right: 20px;
          @media only screen and (min-width: $mobile) {
            margin-right: 30px;
          }
        }

        @media only screen and (min-width: $mobile) {
          margin: 0 10px;
        }

        *  {
          @include font-innocent-bold;
          font-size: 28px;
          line-height: 34px;
        }
      }

      picture {
        width: 80px;
        height: 244px;
        display: block;
      }
    }

    #mobile-logo {
        background-image: url(data-url:resources/images/kids/icons/kids-logo-mobile.svg);
    }

    #icons-container-mobile {
      .cmp-button span {
        width: 32px;
        height: 32px;
        display: block;
        background-repeat: no-repeat;
        background-size: cover;

        &.cmp-button__icon--x-social {
          height: 32px;
          margin-top: 0;
        }
      }

      .cmp-button__icon--facebook {
        background-image: url(data-url:resources/images/kids/icons/facebook-kids-icon.svg);
      }

      .cmp-button__icon--x-social {
        background-image: url(data-url:resources/images/kids/icons/x-social-icon.svg);
      }

      .cmp-button__icon--instagram {
        background-image: url(data-url:resources/images/kids/icons/instagram-kids-icon.svg);
      }


      .cmp-button__icon--instagram {
        margin: 0 20px;
      }
    }


    #last-section-footer {
      background-color: $bg-last-section-footer;

      @media (max-width: $tablet) {
        background-image: url(data-url:resources/images/kids/icons/footer-bgimg-mobile.svg);
        background-size: cover;
        background-color: inherit;
      }

      #text-container {
        .text p {
          color: $color-text-footer;
        }
      }

      #icons-container {

        .cmp-button span.cmp-button__icon--x-social {
          height: 32px;
          margin-top: 0;
        }

        .cmp-button__icon--facebook {
          background-image: url(data-url:resources/images/kids/icons/facebook-kids-icon.svg);
        }

        .cmp-button__icon--x-social {
          background-image: url(data-url:resources/images/kids/icons/x-social-icon.svg);
        }

        .cmp-button__icon--instagram {
          background-image: url(data-url:resources/images/kids/icons/instagram-kids-icon.svg);
        }

        @media (max-width: $tablet) {
          display: none;
        }
      }

      .text p {
        color: $color-text-footer;
      }

      .bottom-wave {
        display: none;
      }
    }

    #icons-container-mobile {
      display: none;

      @media (max-width: $tablet) {
        display: flex;
        max-width: 90%;
        margin-inline: auto;
        padding-bottom: 23px;
      }
    }

    #footer-list-links {
      @media only screen and (max-width: $mobile) {
        padding-top: 0;
      }

      .cmp-list__item-title {
        color: $color-text-footer;
      }

      .cmp-list li a:hover {
        color: $color-text-footer;
      }

      .bottom-wave {
        display: none;
      }
    }

    #footer-links {
      .cmp-button__text {
        color: $color-text-footer;
      }

      .cmp-button:hover {
        color: $color-text-footer;
      }
    }

    #policy-container {
      .cmp-button__text {
        color: $color-text-footer;
      }

      .cmp-button:hover {
        color: $color-text-footer;
      }

      @media (max-width: $tablet) {
        display: flex;
        padding: 80px 0 35px 20px;
        margin-bottom: 0;
        flex-wrap: wrap;
      }

      @media (max-width: $mobile) {
        padding: 30px 20px 15px 0;
      }
    }

    #footer-bottom-text {
      display: none;
    }

    #last-section-footer {
      max-height: 105px;

      @media (min-width: $mobile) and (max-width: $tablet) {
        max-height: 130px;
      }

      @media (max-width: $tablet) {
        max-height: 300px;
      }
    }
  }
}