h1,
h2,
h3,
h4,
h5,
h6 {
  @include font-innocent-bold;
}

h1 {
  font-size: 3rem;
  line-height: 2.8rem;
  margin-block: 1.5rem;
  @media only screen and (min-width: $tablet) {
    font-size: 4rem;
    line-height: 4rem;
    margin-block: 2rem;
  }
}

h2 {
  font-size: 1.75rem;
  line-height: 2rem;
  margin-block: 1.25rem;
  @media only screen and (min-width: $tablet) {
    font-size: 3rem;
    line-height: 3.375rem;
    margin-block: 1.5rem;

  }
}

h3 {
  font-size: 1.75rem;
  line-height: 2rem;
  margin-block: 1.25rem;
  @media only screen and (min-width: $tablet) {
    font-size: 2.75rem;
    line-height: 3rem;
    margin-block: 1.25rem;
  }
}

h4 {
  font-size: 1.5rem;
  line-height: 1.625rem;
  margin-block: 0.75rem;
  @media only screen and (min-width: $tablet) {
    font-size: 1.75rem;
    line-height: 2.188rem;
    margin-block: 1rem;
  }
}

h5 {
  font-size: 1.25rem;
  line-height: calc(10px + 2ex);
}

h6 {
  font-size: 1rem;
  line-height: calc(10px + 2ex);
}

.title-align-center {
  .cmp-title__text {
    text-align: center;
  }
}

.title-align-right {
  .cmp-title__text {
    text-align: right;
  }
}

.font-weight-thin {
  .cmp-title__text {
    @include font-innocent-light;
  }
}

//For kids title and text

.title-color-lime-lizard {
  .cmp-title__text {
    color: #a2d45e;
  }
}

.title-color-white {
  .cmp-title__text {
    color: white;
  }
}

