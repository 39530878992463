.c-activity-section-separator {
    > .cmp-container {
        position: relative;
        background-image: url('data-url:resources/images/kids/icons/rabbit-leaves-bg.svg');
        background-size: cover;
        height: 120px; 
        @media only screen and (max-width: $tablet) {
            background-image: url('data-url:resources/images/kids/icons/rabbit-leaves-mobile-bg.svg');
        }
    
        .ai-kids-top-left {
            position: absolute;
            top: -205px;
            left: 0;
            z-index: -1; 
            @media only screen and (max-width: $tablet) {
                top: -135px;
            }

            .cmp-image__image {
                width: 320px;
                @media only screen and (max-width: $tablet) {
                    width: 200px;
                }
            }
        }
    
        .ai-kids-top-right {
            position: absolute;
            top: -150px;
            right: 0;
            z-index: -1;
            @media only screen and (max-width: $tablet) {
                top: -110px;
            }

            .cmp-image__image {
                @media only screen and (max-width: $tablet) {
                    width: 180px;
                }
            }
        }
    }
}