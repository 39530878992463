//== XF Footer style, used on page template
$font-size-footer: 20px;
$font-size-mobile: 18px;
$line-height-footer: 24px;

.cmp-experiencefragment--footer {
  padding-top: 25px;
  overflow: hidden;

  >.cmp-container {
    background: $berry;
    position: relative;

    .bottom-wave {
      animation: wave 16s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
      transform: translate3d(0, 0, 0);
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      width: 220vw;
      height: 26px;
      top: -25px;

      @media (max-width: $tablet) {
        height: 10px;
        top: -9px;
      }
    }

    #back-to-top {
      display: none;
    }

    #footer-back-to-top {
      padding-top: 21.75rem;
      font-size: 1rem;
      line-height: 1.5rem;
      position: relative;
      color: $white;
      background: $white;

      .text {
        #main-footer-back-to-top {
          background-position: 0px 0%;
          width: 200px;
          height: 300px;
          position: absolute;
          left: 50%;
          top: 0;
          z-index: 1;
          transform: translate(-50%);
          cursor: pointer;

          * {
            display: none;
          }
        }
      }
    }

    #mobile-logo {
      width: 53px;
      height: 66px;
      display: block;
      margin-bottom: 20px;
      background-image: url(data-url:resources/images/general/icons/logo-mobile.svg);

      @media (min-width: $mobile) {
        display: none;
      }
    }

    #footer-list-links {
      padding-top: 60px;
      max-width: 90%;
      margin-inline: auto;
      position: relative;

      @media (min-width: $tablet) {
        display: grid;
        grid-template-columns: repeat(4, 25%);
        grid-gap: 20px;
        max-width: 1210px;
        padding-left: 60px;
        padding-right: 60px;

        .button {
          display: none;
        }
      }

      .bottom-wave {
        fill: $berry;
        left: -18vw;
        right: -18vw;
      }

      .cmp-list {
        li a:hover {
          text-decoration: underline;
          color: $white;
        }

        li {
          @include font-innocent-light;
          font-weight: 400;
          font-size: $font-size;
          line-height: $line-height-footer;
          margin-bottom: 5px;

          @media (max-width: $tablet) {
            display: none;
          }
        }

        li:first-child {
          @include font-innocent-bold;
          font-size: $font-size-footer;
          line-height: 26px;
          margin-bottom: 22px;

          @media (max-width: $tablet) {
            font-size: $font-size-mobile;
            display: inline-block;
            width: 100%;
            margin-bottom: 0;
          }
        }
      }

      .list {
        .cmp-list__item {
          @media (max-width: $tablet) {
            margin: 0px;
          }
        }
      }

      .cmp-list__item-title {
        color: $white;
      }
    }

    #footer-links {
      display: flex;
      padding-top: 60px;
      padding-bottom: 60px;

      @media (min-width: $tablet) {
        padding-top: 100px;
        padding-bottom: 100px;
      }

      >div {
        max-width: 90%;
        margin-inline: auto;
        display: inline-block;

        @media (min-width: $tablet) {
          display: grid;
          grid-template-columns: repeat(4, 25%);
          grid-gap: 20px;
          max-width: 1210px;
          padding-left: 60px;
          padding-right: 60px;
        }

        &:before,
        &:after {
          display: none;
        }
      }

      .cmp-button {
        &:hover {
          text-decoration: underline;
          color: $white;
        }
      }

      .cmp-button__text {
        text-decoration: none;
        @include font-innocent-bold;
        font-size: $font-size-footer;
        line-height: 26px;
        color: $white;

        @media (max-width: $tablet) {
          font-size: $font-size-mobile;
        }
      }
    }

    #last-section-footer {
      padding: 10px 50px;
      background: $red;
      position: relative;
      display: grid;
      grid-template-columns: 40% 20% 40%;
      grid-column-gap: 20px;
      align-items: center;
      justify-content: center;

      @media (min-width: $mobile) and (max-width: $tablet) {
        width: 100%;
      }

      @media (max-width: $tablet) {
        display: inline-block;
        width: 100%;
      }

      >.container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
      }

      #text-container {
        .text p {
          padding-top: 19px;
          max-width: 440px;
          margin-left: auto;
          margin-right: auto;
          @include font-innocent-light;
          font-style: normal;
          font-weight: 400;
          font-size: $font-size;
          line-height: $line-height-footer;
          align-items: center;
          letter-spacing: 0.1px;
          color: $white;
          margin-bottom: 10px;

          @media (max-width: $tablet) {
            text-align: center;
            padding: 15px;
            font-size: 14px;
          }
        }

        .text i {
          font-style: normal;
        }
      }

      #icons-container {
        @media (max-width: $tablet) {
          margin: 20px 0 10px 0;
        }

        .cmp-button span {
          width: 32px;
          height: 32px;
          display: block;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 100%;

          &.cmp-button__icon--x-social {
            height: 25px;
            margin-top: 3px;
          }
        }

        .cmp-button__icon {
          margin: 0 15px;
        }
        
      }

      #icons-container:hover {
        .cmp-button__icon--facebook {
          background-image: url("data-url:resources/images/kids/icons/facebook-light-black.svg");
        }

        .cmp-button__icon--instagram {
          background-image: url("data-url:resources/images/kids/icons/instagram-light-black.svg");
        }

        .cmp-button__icon--x-social {
          background-image: url("data-url:resources/images/kids/icons/x-social-light-black.svg");
        }

        .cmp-button__icon--tiktok {
          background-image: url("data-url:resources/images/kids/icons/tik-tok-light.svg");
        }
      }

      #icons-container {
        .cmp-button__icon--facebook {
          background-image: url("data-url:resources/images/general/icons/facebook-white.svg");

          &:hover {
            background-image: url("data-url:resources/images/general/icons/facebook-white.svg");
          }
        }

        .cmp-button__icon--instagram {
          background-image: url("data-url:resources/images/general/icons/instagram-white.svg");

          &:hover {
            background-image: url("data-url:resources/images/general/icons/instagram-white.svg");
          }
        }

        .cmp-button__icon--x-social {
          background-image: url("data-url:resources/images/general/icons/x-social.svg");

          &:hover {
            background-image: url("data-url:resources/images/general/icons/x-social.svg");
          }
        }

        .cmp-button__icon--tiktok {
          background-image: url("data-url:resources/images/general/icons/tik-tok-icon.svg");

          &:hover {
            background-image: url("data-url:resources/images/general/icons/tik-tok-icon.svg");
          }
        }
      }

      .bottom-wave {
        fill: $red;
      }
    }

    #icons-container,
    #policy-container {
      display: flex;
      justify-content: center;
      text-align: center;
    }


    #policy-container {
      flex-wrap: wrap;

      @media (max-width: $tablet) {
        display: block;
      }

      @media (max-width: $mobile) {
        padding: 30px 0 15px;
      }

      .button {
        padding: 0 10px;
      }

      .cmp-button {
        &:hover {
          text-decoration: underline;
          color: $white;
        }
      }

      .cmp-button__text {
        @include font-innocent-light;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: $line-height-footer;
        letter-spacing: 0.1px;
        color: $white;
      }
    }

    .cmp-list__item-title {
      font-style: normal;
      color: $color-text-footer;

      @media (max-width: $mobile) {
        font-size: $font-size-mobile;
        margin-bottom: 0px;
      }
    }
  }

  .text {
    #footer-bottom-text {
      padding: 20px 10px;

      * {
        margin: 0;
        font-size: 0.875rem;
        color: $berry;
      }

      b {
        @include font-innocent-bold;
      }
    }
  }

  #mobile-main-logo {
    width: 194px;
    height: 64px;
    display: block;
    margin-bottom: 20px;
    background-image: url(data-url:resources/images/general/icons/innocent-logo-text.png);

    @media (min-width: $tablet) {
      display: none;
    }
  }
}