.c-olympics-video {
    position: relative;
    background-color: #F5B6CD;

    &>.cmp-container>.container {
        @media only screen and (min-width: calc($tablet + 1px)) {
            &:before {
                content: "";
                display: block;
                background-size: cover;
                background-image: url(data-url:resources/images/olympics/hand-flag-left.svg);
                height: 160px;
                width: 177px;
                position: absolute;
                top: 50%;
            }
        }
    }

    &:before {
        @media only screen and (min-width: calc($tablet + 1px)){
            content: "";
            display: block;
            position: absolute;
            background-size: cover;
            background-image: url(data-url:resources/images/olympics/clapping-hands.svg);
            width: 120px;
            height: 168px; /* svg height */
            right: 0;
            z-index: 2;
            bottom: 0;
            transform: translateY(20%);
        }
    }
}