.c-two-teasers {
    position: relative;

    &:after {
        content: "";
        background-image: url(data-url:resources/images/kids/icons/kids-two-teasers-background.svg);
        background-size: cover;
        position: absolute;
        bottom: -4px;
        z-index: 9;
        width: 100%;
        height: 106px;

        @media only screen and (max-width: $laptop) {
            bottom: -47px;
        }

        @media only screen and (max-width: $tablet) {
            background-image: none;
        }

    }

    .cmp-container {
        position: relative;
        display: flex;
        background-repeat: no-repeat;

        @media only screen and (max-width: $tablet) {
            display: inherit;
        }

        .teaser:first-child {
            background-color: $lime-lizard;
            flex: 1;
            padding-bottom: 120px;
            position: relative;

            @media only screen and (max-width: $tablet) {
                background-image: url(data-url:resources/images/kids/icons/kids-two-teasers-dark-green-mobile.svg);
                background-size: cover;
                background-color: transparent;
            }

            @media only screen and (max-width: $mobile) {
                background-size: 100% 136%;
                background-color: transparent
            }

            .cmp-teaser__title {
                @media only screen and (max-width: $tablet) {
                    padding-top: 80px;
                }
            }
        }

        .teaser:nth-child(2) {
            background-color: #ECF6DF;
            flex: 1;
            padding-bottom: 170px;
            position: relative;

            .cmp-teaser__action-container {
                background-color: $black;
                position: absolute;
                bottom: 115px;
                left: 50%;
                transform: translateX(-50%);

                @media only screen and (max-width: $tablet) {
                    bottom: 90px;
                    left: 50%;
                }

                .cmp-teaser__action-link {
                    color: $white;
                }

                .cmp-teaser__action-link:hover {
                    color: $white;
                }
            }

        }
    }

    .cmp-teaser__content {
        padding-top: 75px;
    }

    .cmp-teaser__title {
        @include font-innocent-bold;
        font-size: 38px;
        line-height: 46px;
        text-align: center;
        color: $black;
        margin-bottom: 5px;
        padding: 0 15px;
    }

    .cmp-teaser__description {
        p {
            @include font-innocent-light;
            font-size: 22px;
            line-height: 32px;
            color: $black;
            max-width: 350px;
            display: inline-block;
            margin-bottom: 0;
            padding: 0 15px;
        }
    }

    .cmp-teaser__action-container {
        @include font-innocent-bold;
        background-color: $white;
        border-radius: 30px;
        font-size: 20px;
        line-height: 26px;
        display: inline-block;
        position: absolute;
        bottom: 115px;
        left: 50%;
        transform: translateX(-50%);
        max-width: initial;

        @media only screen and (max-width: $tablet) {
            bottom: 80px;
            left: 50%; 
        }

        .cmp-teaser__action-link {
            margin: 0;
            padding: 10px 25px;

            @media only screen and (min-width: $mobile) {
                font-size: 20px;
            }
        }

        .cmp-teaser__action-link:hover {
            color: $black;
        }

        .button-icon {
            display: none;
        }

        .button-background {
            display: none;
        }
    }

    .cmp-teaser__image {
        margin: 10px auto 0 auto;
        max-width: 340px;
    }
}