.container-full-screen {

    .cmp-embed {
        position: relative;
        width: 100%;
        @media (min-width: $laptop) {
            min-height: 100vh;
        }
    }

    .cmp-embed__video__container {
        @media (min-width: $laptop) {
            position: absolute;
            inset: 0;
        }
    }

    video {
        aspect-ratio: 16 / 9;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.aem-AuthorLayer-Edit  {
    .container-full-screen {
        .cmp-embed {
            min-height: 100%;
            position: initial;
        }

        .cmp-embed__video__container {
            position: initial;
        }
    }
}