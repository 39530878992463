.xf-footer-olympics .cmp-experiencefragment--footer {
  >.cmp-container {
    background: $pink;

    #footer-list-links {

      .bottom-wave {
        fill: $pink;
      }
    }

    #last-section-footer {
      background: map-get($colors, dark-pink);

      .bottom-wave {
        fill: map-get($colors, dark-pink);
      }
    }
  }

  .text {
    #footer-bottom-text {
      * {
        color: $dark-pink;
      }
    }
  }
}