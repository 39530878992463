.title-olympics {
    .cmp-title__text {
        display: grid;
        grid-template-columns: 21px auto 21px;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-block: 0.5rem;
        color: map-get($colors, dark-pink);
        font-size: 2.25rem;

        @media only screen and (min-width: $mobile) {
            font-size: 3rem;
        }

        @media only screen and (min-width: calc($tablet + 1px)) {
            font-size: 3.75rem;
            margin-block: 0.75rem;
        }

        .title-marker-left {
            order: -1;
            margin-left: -5px;

            @media only screen and (min-width: $mobile) {
                margin-left: -10px;
            }
        }

        .title-marker-right {
            margin-left: 5px;

            @media only screen and (min-width: $mobile) {
                margin-left: 10px;
            }
            
        }

        .title-marker-mobile {
            @media only screen and (min-width: $mobile) {
                display: none;
            }
        }

        svg:not(.title-marker-mobile) {
            display: none;
            @media only screen and (min-width: $mobile) {
                display: inline;
            }
        }

        svg {
            margin-top: 10px;
            width: 21px;

            @media only screen and (min-width: $mobile) {
                margin-top: 15px;
                width: 26px;
            }

            @media only screen and (min-width: $tablet) {
                margin-top: 18px;
            }
        }
    }
}