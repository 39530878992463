.t-vertical {
    position: relative;

    .cmp-teaser {
        width: 90%;
        padding: 20px;
        display: flex;
        flex-direction: column-reverse;

        @media only screen and (max-width: $mobile) {
            width: inherit;
        }
    }

    .cmp-teaser__content {
        text-align: left;
    }

    .cmp-teaser__title {
        svg {
            display: block;
            max-width: 100%;
            path {
                stroke-dashoffset: 0 !important;
            }
        }
    }
   
    &.t-mask-square {
        .cmp-teaser__image {
            mask: url("data-url:resources/images/general/masks/teaser-vertical-mask.svg") center / contain no-repeat;
        }
    }

    @include teaser-mask-curvy;
}

@each $key,
$val in $colors {
    .t-scheme-#{$key} {
        .cmp-teaser__title {
            svg path {
                stroke: #{$val};
            }
        }
    }
}