.c-olympics-top {
    position: relative;
    background-color: rgb(232, 69, 129);
    padding-top: 96px;

    &>.cmp-container>.container {
        position: relative;
        margin-bottom: 200px;

        @media only screen and (min-width: calc($tablet)) {
            margin-bottom: 100px;

            &:before,
            &:after {
                content: "";
                display: block;
                background-size: cover;
            }

            &:before {
                background-image: url(data-url:resources/images/olympics/hand.svg);
                height: 149px;
                width: 108px;
                position: absolute;
                bottom: 20px;
            }

            &:after {
                background-image: url(data-url:resources/images/olympics/arrow-down.svg);
                width: 76px;
                height: 93px;
                position: relative;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    &:before {
        content: "";
        display: block;
        position: absolute;
        background-size: cover;
        background-image: url(data-url:resources/images/olympics/hand-flag.svg);
        width: 354px;
        height: 281px; /* svg height */
        bottom: -90px;
        right: 0;
        z-index: 2;

        @media only screen and (min-width: calc($mobile + 1px)) {
            bottom: 0;
        }
    }

    &:after {
        content: "";
        display: block;
        width: 100%;
        position: relative;
        background-size: cover;
        background-image: url(data-url:resources/images/olympics/bottom-wave-mobile.svg);
        background-position: bottom;
        padding-top: 64px; /* svg height */
        bottom: -63px;
        z-index: 1;

        @media only screen and (min-width: calc($mobile + 1px)) {
            background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='1422' height='64' viewBox='0 0 1422 64' fill='none'><path d='M1791 28.462C1725.44 7.89202 1600.66 3.432 1421.49 0.342005C1324.68 -1.32799 1212 2.95201 1084.19 16.852C719.85 56.482 584.51 23.882 402.35 10.022C220.18 -3.83799 -9 8.42201 -9 8.42201V63.102H1791V28.452V28.462Z' fill='%23fff'/></svg>");
            padding-top: calc((64 / 1422) * 100%); /* Aspect ratio padding-top: height 64, width 1422 */
            bottom: -2px;
        }
    }

    .title-olympics {
        .cmp-title__text {
            color: white;
        }

        [class*="title-marker"] path {
            stroke: white;
        }
    }

    &.blossom-wave-olympics {
        &:after {
            @media only screen and (min-width: calc($mobile + 1px)) {
                background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='1422' height='64' viewBox='0 0 1422 64' fill='none'><path d='M1791 28.462C1725.44 7.89202 1600.66 3.432 1421.49 0.342005C1324.68 -1.32799 1212 2.95201 1084.19 16.852C719.85 56.482 584.51 23.882 402.35 10.022C220.18 -3.83799 -9 8.42201 -9 8.42201V63.102H1791V28.452V28.462Z' fill='%23F5B6CD'/></svg>");
            }
        }
    }
}