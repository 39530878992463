@each $key,
$val in $colors {
  [class*="acc-color-#{$key}"] {
    .cmp-accordion__title {
      color: #{$val};
    }

    .cmp-accordion__icon {
      background-color: #{$val};
    }

    .cmp-accordion__button svg path {
      stroke: #{$val};
    }
  }
}

[class*="acc-color"] {
  .cmp-accordion {
    position: relative;
    max-width: 1340px;
    margin: 0 auto;
    padding: 0 20px;

    @media only screen and (max-width: $tablet) {
      padding: 0;
    }
  }

  .cmp-accordion__button svg path {
    stroke-dashoffset: 0 !important;
  }

  .cmp-accordion__button {
    cursor: pointer;
    background-repeat: no-repeat;
    width: 608px;
    padding: 16px 0 16px 0;
    position: relative;
    display: grid;
    grid-template-columns: 50px auto;
    background-position: left bottom;

    @media only screen and (max-width: $laptop) {
      width: 100%;
    }

    .cmp-accordion__icon {
      order: -1;
      display: block;
      width: 33px;
      height: 33px;
      transition: transform 0.3s;
      background-repeat: no-repeat;
      border-radius: 25px;
      background-position: center;
      background-image: url("data-url:resources/images/general/icons/accordion-plus.svg");
    }

    &:hover {
      .cmp-accordion__icon {
        transform: scale(0.9);
      }
    }
  }

  .cmp-accordion__button--expanded {
    .cmp-accordion__icon {
      display: block;
      background-position: center;
      background-image: url("data-url:resources/images/general/icons/accordion-minus.svg");
    }
  }

  .cmp-accordion__title {
    @include font-innocent-bold;
    font-size: 22px;
    vertical-align: top;
    line-height: 34px;
  }

  .cmp-accordion__panel {
    padding: 30px 1em 0.1em 1em;
    position: relative;
    right: 0;
    animation: zoom-in 0.4s ease-in-out forwards;

    @media only screen and (max-width: $tablet) {
      width: inherit;
    }
  }

  p {
    @include font-innocent-light;
    font-weight: 100;
    font-size: 16px;
    line-height: 24px;
    color: $black;
    margin: 0 0 2.109rem;

    @media only screen and (max-width: $mobile) {
      font-size: 18px;
      line-height: 28px;
    }
  }

  ul {
    list-style: disc;
    padding-left: 15px;

    li {
      @include font-innocent-light;
      font-size: 18px;
      line-height: 25px;
      
      @media screen and (max-width: $mobile) {
      font-size: 16px;
      line-height: 18px;
      }
    }
  }
}

.acc-color-white-berry,
.acc-color-white-broccoli,
.acc-color-white-grape {
  .text p {
    color: white;
    @include font-innocent-light;
    max-width: 100%;

    a {
      color: white;
      text-decoration: underline;
      // font-weight: bold;
    }
  }
}

.acc-color-white-broccoli {
  .cmp-accordion__button {
    .cmp-accordion__icon {
      background-image: url("data-url:resources/images/general/icons/accordion-plus-white-broccoli.svg");
    }
  }

  .cmp-accordion__button--expanded {
    .cmp-accordion__icon {
      background-image: url("data-url:resources/images/general/icons/accordion-minus-white-broccoli.svg");
    }
  }
}

.acc-color-white-berry {
  .cmp-accordion__button {
    .cmp-accordion__icon {
      background-image: url("data-url:resources/images/general/icons/accordion-plus-white-berry.svg");
    }
  }

  .cmp-accordion__button--expanded {
    .cmp-accordion__icon {
      background-image: url("data-url:resources/images/general/icons/accordion-minus-white-berry.svg");
    }
  }
}

.acc-color-white-grape {
  .cmp-accordion__button {
    .cmp-accordion__icon {
      background-image: url("data-url:resources/images/general/icons/accordion-plus-white-grape.svg");
    }
  }

  .cmp-accordion__button--expanded {
    .cmp-accordion__icon {
      background-image: url("data-url:resources/images/general/icons/accordion-minus-white-grape.svg");
    }
  }
}

.acc-color-dark-pink {
  .cmp-accordion__title {
    @media screen and (max-width: $mobile) {
      font-size: 18px;
      line-height: 1.2;
    }
  }
  .cmp-accordion__button {
    padding-bottom: 0;
    align-items: center;
  }

  .cmp-accordion__panel {
    padding: 10px 1em 0.1em;
  }

  .cmp-accordion {
    max-width: 600px;
  }
}

@keyframes zoom-in {
  0% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}