.c-activity-badge {

    > .cmp-container {
        position: relative;
        padding-bottom: 300px;
        padding-top: 100px;

        @media only screen and (max-width: $tablet) {
            padding-bottom: 90px;
            padding-top: 60px;
        }
    }

    .ai-kids-top-right,
    .ai-kids-bottom-left {
        position: initial;
        .cmp-image__image {
            width: 100%;
            max-width: 80%;
            
            @media only screen and (max-width: $mobile) {
                max-width: 90%; 
            }
        }
    }

    .cmp-teaser {
        display: flex;
        flex-direction: column-reverse;
        max-width: 680px;
        margin: 0 auto;
        padding: 0 20px;

        picture {
            display: block;
            margin: 0 auto;
            max-width: 420px; 
        }
    }
}