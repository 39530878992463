.ai-kids-top-left {
    position: relative;

    .cmp-image__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 250px;

        @media only screen and (max-width: $tablet) {
            width: 200px;
        }

        @media only screen and (max-width: $mobile) {
            width: 130px;
        }
    }
}