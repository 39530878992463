// Initial Variables

//== Font

$font-size: 16px;

//== Color
$color-text-footer: #212721;

//== Breakpoints

$mobile: 767px;
$tablet: 1023px;
$laptop: 1200px;


// Innocent Variables

$almond: rgb(171, 92, 87);
$berry: rgb(224, 62, 82);
$strawberry: rgb(203, 44, 48);
$broccoli: rgb(0, 150, 108);
$grape: rgb(142, 58, 128);
$mango: rgb(255, 158, 27);
$red: rgb(179, 8, 56);
$water: rgb(0, 167, 181);
$white: rgb(255, 255, 255);
$daisy: rgb(248, 250, 251);
$cloud: rgb(221, 229, 237);
$lime: rgb(161, 216, 132);
$cherry: rgb(165, 0, 52);
$white-cloud: rgb(251, 252, 253);
$apple: rgb(116, 170, 80);
$spinach: rgb(0, 87, 63);
$blood-orange: rgb(220, 68, 5);
$raspberry: rgb(239, 51, 64);
$peach: rgb(255, 177, 187);
$lemon: rgb(243, 208, 62);
$banana: rgb(255, 200, 69);
$pine-apple: rgb(251, 216, 114);
$orange: rgb(232, 119, 34);
$plum: rgb(95, 33, 103);
$dragon-fruit: rgb(231, 72, 131);
$blackcurrant: rgb(122, 65, 131);
$pear: rgb(226, 232, 104);
$beetroot: rgb(174, 37, 11);
$blossom: rgb(245, 182, 205);
$guava: rgb(246, 117, 153);
$algae: rgb(39, 153, 137);
$ocean: rgb(0, 115, 152);
$glacier: rgb(103, 210, 223);
$hazelnut: rgb(130, 59, 52);
$latte: rgb(213, 162, 134);
$oat: rgb(238, 212, 132);
$cashew: rgb(239, 209, 159);
$spirulina: rgb(0, 98, 105);
$sky: rgb(113, 197, 232);
$blueberry: rgb(27, 54, 93);
$coffee: rgb(91, 52, 39);
$cocoa: rgb(164, 90, 42);
$chai: rgb(174, 138, 121);
$soft-pink: rgb(236, 185, 204);



// Primary colors
$black: rgb(33, 39, 33);
$green: rgb(33, 147, 118);
$orange: rgb(247, 148, 30);

// The most used colors

$colors: (
  almond: rgb(171, 92, 87),
  berry: rgb(224, 62, 82),
  blackcurrant: rgb(122, 65, 131),
  broccoli: rgb(0, 150, 108),
  spinach: rgb(0, 87, 63),
  grape: rgb(142, 58, 128),
  glacier: rgb(103, 210, 223),
  mango: rgb(255, 158, 27),
  red: rgb(179, 8, 56),
  water: rgb(0, 167, 181),
  white: rgb(255, 255, 255),
  daisy: rgb(248, 250, 251),
  cloud: rgb(221, 229, 237),
  lime: rgb(161, 216, 132),
  apple: rgb(116, 170, 80),
  peach: rgb(255, 177, 187),
  guava: rgb(246, 117, 153),
  oat: rgb(238, 212, 132),
  banana: rgb(255, 200, 69),
  orange: rgb(232, 119, 34),
  sky: rgb(113, 197, 232),
  ocean: rgb(0, 115, 152),
  algae: rgb(39, 153, 137),
  spirulina: rgb(0, 98, 105),
  sharpieBlack: rgb(33, 39, 33),
  dark-red: rgb(156, 34, 53),
  dark-pink: rgb(213,81,131),
  pink: rgb(246,117,153)
);

$kids-colors: (
  pear: #e2e868,
  peach-pink: #ffb1bb,
  apple-juice: #d7e399,
  sra: #a2d45e,
  cas: #EF86CE,
  omp: #FFC629,
  pineapple: #fbd872,
  banana: #f3d03e,
  blossom: #f5b6cd,
  ab: #A7A4DF,
  beetroot: #ae2573,
  orange: #e87722,
  pac: #F88D2A,
  oat: #f3D03E,
  apple: #74AA50,
  pop: #FF647D,
  mango: #FF9E1B,
  raspberry: #ef3340,
  lime-lizard: #A2D45E,
  lime: #a1d884,
  spinach: #00573F
);

  $pac: #F88D2A;
  $omp: #FFC629;
  $cas: #EF86CE;
  $ab: #A7A4DF;
  $pop: #FF647D;
  $lime-lizard: #A2D45E;
  $apple: #74AA50;
  $spinach: #00573F; 
  $dark-grey: #ffffff80;
  $dark-pink: #D55183;
  $light-pink: #ECB9CC;
  $pink: #F67599; 
