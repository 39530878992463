.teaser {
  text-align: center;

  .cmp-teaser__title {
    margin-bottom: 1rem;
  }

  .cmp-teaser__description p {
    color: #212721;
    font-size: 1rem;
    a {
      text-decoration: underline;
      color: inherit;
    }
  }

  .cmp-teaser__description h2,h3,h4,h5,h6 {
    a {
      text-decoration: none;
      color: inherit;
        &:hover {
          text-decoration: underline;
        }
    }     
  }



  .cmp-teaser__action-container {
    position: relative;
    z-index: 1;
    display: inline-block;
    gap: 10px;
    min-height: 44px;
    text-decoration: none;
  }

  .cmp-teaser__action-link {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    color: #212721;
    @include font-innocent-bold;
    font-size: 19px;
    transition: color 0.5s ease;
    z-index: 2;
    margin-left: 10px;
    line-height: 1;
    padding: 8px 15px;

    &:hover {
      color: #fff;

      .button-icon {
        fill: #fff;
      }
    }
  }

  .button-background {
    transition: fill 0.5s ease, stroke 0.5s ease;
    position: absolute;
    z-index: -1;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .button-icon {
    margin-right: 10px;
    margin-left: 10px;
    z-index: 2;
    fill: #212721;
    transition: fill 0.5s ease;
  }

  &[class*="t-marker"] {
    .cmp-teaser__title {
      display: grid;
      grid-template-columns: 21px auto 21px;
      align-items: center;
      justify-content: center;

      .title-marker-left {
        order: -1;
        margin-left: -10px;
      }

      .title-marker-right {
        margin-left: 10px;
      }

      svg {
        width: 21px;
      }
    }
  }

  &.t-left-align {
    text-align: left;
  }

  &.t-color-white {
    .cmp-teaser__title {
      color: #fff;
    }
  }

  &.t-color-sharpie-black {
    .cmp-teaser__title {
      color: $black;
    }
  }

  &.t-btn-blooming {
    .cmp-teaser__action-link {
      position: relative;
      height: 44px;
      padding-left: 30px;

      &:after,
      &:before {
        content: "";
        display: block;
        position: absolute;
        pointer-events: none;
        animation-duration: 0.3s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
      }

      &:before {
        z-index: -1;
        left: -20px;
        top: -30px;
      }

      &:after {
        z-index: -1;
        right: -20px;
        top: -24px;
      }

      &.zoom-in {
        &:before {
          content: "";
          width: 69px;
          height: 97px;
          background-image: url(data-url:resources/images/rewild/button/flower-left.svg);
          z-index: 2;
          animation-name: zoomIn;
        }

        &:after {
          content: "";
          width: 43px;
          height: 55px;
          background-image: url(data-url:resources/images/rewild/button/flower-right.svg);
          z-index: 2;
          animation-name: zoomIn;
        }
      }

      &.zoom-out {
        &:before {
          content: "";
          width: 69px;
          height: 97px;
          background-image: url(data-url:resources/images/rewild/button/flower-left.svg);
          z-index: 2;
          animation-name: zoomOut;
        }

        &:after {
          content: "";
          width: 43px;
          height: 55px;
          background-image: url(data-url:resources/images/rewild/button/flower-right.svg);
          z-index: 2;
          animation-name: zoomOut;
        }
      }
    }
  }
  &[class*="t-potato"],
  &.t-mask-square {
    .cmp-teaser__image {
      display: grid;
      svg {
        grid-column: 1;
        grid-row: 1;
        margin: auto;
        height: auto;
        max-width: 100%;
      }
    }
  
    .cmp-adaptive-image {
      grid-row: 1;
      grid-column: 1;
      width: calc(100% - 100px);
      margin: auto;
    }
  
    picture {
      -webkit-mask: url(data-url:resources/images/general/masks/common-split-mask-image.svg)
        center / contain no-repeat;
      object-fit: cover;
      padding-bottom: 100%;
      overflow: hidden;
      position: relative;
      display: block;
    }
  
    .cmp-image__image {
      width: 100%;
      max-width: none;
      position: absolute;
      top: 50%;
      right: 0;
      bottom: 0;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }

  &.t-btn-link-list {
    ul {
      li {
        list-style: none;
        margin-bottom: 10px;

        a {
          display: inline-flex;
          flex-direction: row-reverse;
          gap: 20px;
          align-items: center;
          font-size: 18px;
        }
      }
    }
  }

  &[class*="flyout-"] {
    .cmp-teaser {
      position: relative;
      padding-top: 100px;
    }

    .cmp-teaser__pretitle {
      @include font-innocent-super-scrawl;
      font-size: 30px;
      line-height: 23px;
      margin-bottom: 0;
      display: block;
      text-align: center;
      white-space: pre-wrap;
      z-index: 10;
    }

    .cmp-teaser__pretitle:after {
      background-image: url("data-url:resources/images/general/icons/flyoutArrow.svg");
      background-repeat: no-repeat;
      width: 100px;
      height: 30px;
      content: "";
      transform-origin: left center;
    }
  }

  &[class*="flyout-top-left"] {
    .cmp-teaser__pretitle {
      max-width: 140px;
      position: absolute;
      top: 4%;
      left: 2%;
      z-index: 10;
      transform: rotate(-20deg);
    }

    .cmp-teaser__pretitle:after {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: rotate(90deg) scale(0.75) translate(40px);
    }
  }

  &[class*="flyout-top-right"] {
    .cmp-teaser__pretitle {
      max-width: 140px;
      position: absolute;
      top: -40px;
      right: -20px;
      transform: rotate(20deg);
    }

    .cmp-teaser__pretitle:after {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: rotate(280deg) scaleX(-1) translate(25px);
    }
  }

  &[class*="flyout-bottom-right"] {
    .cmp-teaser__content {
      position: relative;
      display: inline-block;
    }

    .cmp-teaser__pretitle {
      width: 80px;
      position: absolute;
      bottom: 15%;
      left: 105%;
      transform: rotate(20deg) translate(100px);

      @media only screen and (max-width: $mobile) {
        display: none;
      }
    }

    .cmp-teaser__pretitle:after {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: rotate(180deg) scale(0.75) translate(20px);

      @media only screen and (max-width: $mobile) {
        display: none;
      }
    }
  }
}

@each $key, $val in $colors {
  .t-scheme-#{$key} {
    .cmp-teaser__title {
      color: #{$val};
    }

    .cmp-teaser__description u svg path {
      stroke: #{$val};
    }

    .cmp-teaser__action-link {
      .button-background {
        fill: rgba(171, 92, 87, 0);
        stroke: #{$val};
      }

      &:hover {
        .button-background {
          fill: #{$val};
        }
      }
    }

    &.t-btn-link-list {
      ul li a {
        color: #{$val};

        svg path {
          fill: #{$val};
        }
      }
    }
  }

  .t-desc-#{$key} {
    .cmp-teaser__description *,
    .cmp-teaser__description p {
      color: #{$val};
    }
  }

  .t-potato-#{$key} {
    .cmp-teaser__image {
      svg path {
        fill: #{$val};
      }
    }
  }

  .t-marker-#{$key} {
    .cmp-teaser__title {
      svg {
        fill: #{$val};
      }
    }
  }
}

.t-scheme-white {
  .cmp-teaser__title {
    color: white;
  }

  .cmp-teaser__action-link {
    .button-background {
      fill: #fff;
      stroke: #fff;
    }

    &:hover {
      .button-background {
        fill: transparent;
      }
    }
  }
}


