.c-dots-light-blue {
  .swiper-pagination-bullet path {
    fill: #6DCBC2;
  }
}

.c-dots-broccoli {
  .swiper-pagination-bullet path {
    fill: $broccoli;
  }
}

.c-dots-lime {
  .swiper-pagination-bullet path {
    fill: $lime;
  }
}

.c-dots-dark-orange {
  .swiper-pagination-bullet path {
    fill: #fea835;
  }
}

.c-dots-mango {
  .swiper-pagination-bullet path {
    fill: $mango;
  }
}

.c-dots-water {
  .swiper-pagination-bullet path {
    fill: $water;
  }
}

.c-teaser-slider {
  padding-bottom: 100px !important;

  @media only screen and (min-width: $tablet) {
    padding-bottom: 0;
  }

  .swiper-pagination {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 20px;

    .swiper-pagination-bullet {
      margin: 0 0.375rem;
      cursor: pointer;
    }
  }

  .swiper-pagination-bullet {
    opacity: .5;
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
  }

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    transform: translateY(-100%);
    bottom: 0;
    z-index: 1;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: transform 0.3s cubic-bezier(0.63, -0.01, 0.26, 1.49);
    top: 40%;

    &:hover {
      transform: scale(0.9) translateY(-100%);
    }

    @media only screen and (min-width: $tablet) {
      bottom: initial;
      transform: translateY(-50%);

      &:hover {
        transform: scale(0.9) translateY(-50%);
      }
    }

    @media only screen and (max-width: $mobile) {
      top: 95%;
    }
  }

  .swiper-button-next {
    width: 40px;
    height: 30px;
    background-image: url('data-url:resources/images/general/icons/slider-arrow-next.svg');
    right: 18px;
  }

  .swiper-button-prev {
    width: 40px;
    height: 30px;
    background-image: url('data-url:resources/images/general/icons/slider-arrow-prev.svg');
    left: 18px;

  }

  .swiper-button-disabled {
    opacity: .5;
    cursor: default;
  }

  .t-common {
    max-width: 100%;

    .cmp-teaser {
      max-width: 1210px;
      margin: 0 auto;

      .cmp-adaptive-image {
        transform: none !important;
      }
      @media only screen and (min-width: $mobile) and (max-width: $laptop) {
          padding: 0 60px;
      }

      @media only screen and (min-width: $laptop) and (max-width: 1300px){
          padding: 0 50px;
        }
    }
  }

  
}