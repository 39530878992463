.t-common {

   @include teaser-common;
   @include teaser-image-right;
   @include teaser-mask-curvy;
    //Additional styles

    &.t-mask-curvy {

        .cmp-teaser__image {
            @media only screen and (min-width: $mobile) {
                margin-right: 50px;
            }
        }

        &.t-img-right {
            .cmp-teaser__image {
                @media only screen and (min-width: $mobile) {
                    margin-left: 50px;
                    margin-right: 0;
                }
            }
        }
    }
}