.c-kids-social-feed.swiper-horizontal {
    
    .swiper-wrapper {
        @media only screen and (min-width: $laptop) {
            justify-content: center;
        }
    }

    .teaser {
        text-align: left;
        height: auto;

        .cmp-teaser {
            height: 100%;
        }

        .cmp-teaser__link {
            display: flex;
            flex-direction: column-reverse;
            height: 105%;
            mask: url('data-url:resources/images/kids/icons/social-feed-mask.svg') center no-repeat;
            position: relative;
            object-fit: cover;
            overflow: hidden;
            mask-size: 100% 100%;
        }

        .cmp-teaser__content {
            background-color: map-get($kids-colors, sra);
            padding: 10px 10px 10px 25px;
            flex: 1;
        }

        .cmp-teaser__description {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;

            @media only screen and (min-width: $tablet) {
                -webkit-line-clamp: 3;
                line-clamp: 3;
            }
        }

        .cmp-teaser__title {
            @include font-innocent-bold;
            font-size: 14px;
            line-height: 1.3;
            color: $black;
            margin-top: 0;
            margin-bottom: 5px;
        }

        .cmp-teaser__description * {
            @include font-innocent-light;
            font-size: 14px;
            line-height: 1.3; 
            margin-bottom: 5px;
        }

        .cmp-teaser__image {
            picture {
                display: flex;
            }
        }

        .cmp-image {
            margin-top: 0;
        }
    }

    .text {
        padding: 0 30px;
        text-align: center;

        p {
            @include font-innocent-bold;
            font-size: 28px;
        }
    }

    .swiper-navigation {
        display: grid;
        grid-template-columns: 45px auto 45px;
        justify-content: center;
        align-items: center;
        margin-top: 80px;
        margin-left: auto;
        margin-right: auto;
        max-width: 90%;

        .text p {
            margin: 0;
        }
    }

    .swiper-button-next,
    .swiper-button-prev {
        display: block;
        width: 43px;
        height: 45px;
        position: relative;
        cursor: pointer;
    }

    .swiper-button-next {
        background-image: url('data-url:resources/images/kids/icons/arrow-next.svg');
    }

    .swiper-button-prev {
        background-image: url('data-url:resources/images/kids/icons/arrow-prev.svg');
    }
}