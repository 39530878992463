p {
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin: 0 0 2.109rem;
  @include font-innocent-light;

  @media only screen and (min-width: $tablet) {
    font-size: 1rem; 
    line-height: 1.5rem;
  }
}

