.acc-kids {

  .cmp-accordion__item[data-cmp-expanded] {
    @media only screen and (max-width: $tablet) {
      padding-bottom: 40px;
      border-bottom: 3px solid  map-get($colors, sharpieBlack);
    }
  }
  
  .cmp-accordion {
    position: relative;
    max-width: 1340px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .cmp-accordion__button {
    background-color: $white;
    border-bottom: 3px solid $black;
    cursor: pointer;
    width: 508px;
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
    display: grid;
    grid-template-columns: 50px auto;
    align-items: center;

    @media only screen and (max-width: $tablet) {
      width: 100%;
      padding-top: 25px;
      padding-bottom: 25px;
    }


    .cmp-accordion__icon {
      order: -1;
      display: block;
      margin-right: 10px;
      width: 25px;
      height: 25px;
      background-image: url('data-url:resources/images/kids/icons/kids-accordion-plus.svg');
    }
  }

  .cmp-accordion__button--expanded {
    @media only screen and (max-width: $tablet) {
      border-bottom: 0;
    }

    .cmp-accordion__icon {
      background-image: url('data-url:resources/images/kids/icons/kids-accordion-minus.svg');
    }

    &:focus {
      @media only screen and (max-width: $mobile) {
        border-bottom: 0;
      }
    }
  }

  .cmp-accordion__title {
    @include font-innocent-bold;
    font-size: 28px;
    vertical-align: middle;
    line-height: 34px;
    color: $black;

    @media only screen and (max-width: $tablet) {
      font-size: 20px;
      line-height: 26px;
    }
  }

  .cmp-accordion__panel {
    padding: .5em 1em;
    position: absolute;
    width: 40%;
    right: 0;
    top: 35px;

    @media only screen and (max-width: $tablet) {
      width: inherit;
      position: inherit;
      padding: 0 10px 0px 60px;
    }
  }

  .cmp-image {
    margin-top: 0;
    @media only screen and (max-width: $tablet) {
      margin-top: 15px;
    }
  }

  .cmp-teaser {
    flex-direction: column-reverse;
    display: flex;
    position: relative;

    .cmp-teaser__content {
      text-align: left;
      margin-top: 35px;
      @media only screen and (max-width: $tablet) {
        margin-top: 20px;
      }
    }

    .cmp-teaser__description {
      p {
        @include font-innocent-light;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        color: $black;
        @media only screen and (max-width: $tablet) {
          margin: 0;
        }
      }
    }
  }

  .cmp-teaser__title {
    @include font-innocent-bold;
    margin-bottom: 1rem;
    font-size: 28px;
    line-height: 34px;
    color: $black;
    height: 26px;

    @media only screen and (max-width: $tablet) {
      font-size: 20px;
      display: none;
    }
  }
}