.c-teaser-slider.olympics {
    .t-common {

        .cmp-teaser__title {
            color: #E74883;
        }

        .cmp-teaser__image {
            display: grid;

            svg {
                grid-column: 1;
                grid-row: 1;
                margin: auto;
                height: auto;
                max-width: 100%;

                path {
                    fill: map-get($kids-colors, blossom);
                }
            }
        }

        .cmp-adaptive-image {
            grid-row: 1;
            grid-column: 1;
            width: calc(100% - 140px);
            margin: auto;

            @media only screen and (max-width: $mobile) {
                width: calc(100% - 100px);
            }
        }

        picture {
            mask: url(data-url:resources/images/general/masks/common-split-mask-image.svg) center / contain no-repeat;;
            -webkit-mask: url(data-url:resources/images/general/masks/common-split-mask-image.svg) center / contain no-repeat;
            object-fit: cover;
            padding-bottom: 100%;
            overflow: hidden;
            position: relative;
            display: block;
        }

        .cmp-image__image {
            width: 100%;
            max-width: none;
            position: absolute;
            top: 50%;
            right: 0;
            bottom: 0;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }

        @media only screen and (min-width: $mobile) and (max-width: $laptop) {
            .cmp-teaser {
                padding: 0 70px;
            }
        } 
        @media only screen and (min-width: $laptop) and (max-width: 1300px) {
            .cmp-teaser {
                padding: 30px 50px;
            }
        }
    }

    .swiper-navigation {

        .swiper-button-next,
        .swiper-button-prev {
            height: 50px;
            width: 49px;

            @media only screen and (max-width: $mobile) {
                top: 190px;
            }
        }

        .swiper-button-next {
            background-image: url("data-url:resources/images/general/icons/olympics-carousel-next.svg");

            @media only screen and (max-width: $mobile) {
                right: 2px;
            }
        }

        .swiper-button-prev {
            background-image: url("data-url:resources/images/general/icons/olympics-carousel-prev.svg");

            @media only screen and (max-width: $mobile) {
                left: 2px;
            }
        }
    }

    .swiper-pagination {
        .swiper-pagination-bullet {
            path {
                scale: 0.9;
                transform-origin: center;
            }
        }

        .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
            stroke: #E74883;
            stroke-width: 2px;
            fill: none;
        }

        .swiper-pagination-bullet-active {
            fill: #E74883
        }

        @media only screen and (max-width: $mobile) {
            margin-top: 0;
        }
    }

    .teaser[class*="flyout-"] {
        .cmp-teaser__pretitle {
            position: absolute;
            display: inline-block;

            &::after {
                content: "";
                position: absolute;
            }
        }

        @media only screen and (max-width: $mobile) {
            .cmp-teaser {
                padding-top: 0;
            }

            .cmp-teaser__content {
                padding-top: 50px;
                position: relative;
            }
        }
    }

    .flyout-olympics-tr {
        .cmp-teaser__pretitle {
            transform: rotate(-6deg);
            top: -5%;
            right: 20%;
            max-width: 180px;

            @media only screen and (min-width: calc($mobile + 1px)) {
                top: 0;
                right: 0;
                transform: rotate(8deg);
            }

            @media only screen and (min-width: 1301px) {
                top: 7px;
            }

            &::after {
                background-image: url('data-url:resources/images/olympics/arrow-flyout-left.svg');
                height: 40px;
                width: 36px;
                right: 0;
                top: 0;
                transform: translate(-100%, -150%) scaleX(-1) rotate(125deg);

                @media only screen and (min-width: calc($mobile + 1px)) {
                    top: unset;
                    bottom: -67px;
                    right: 45px;
                    transform: rotate(-40deg);
                }
            }
        }
    }

    .flyout-olympics-br {
        .cmp-teaser__pretitle {
            width: 160px;
            top: -3%;
            left: 30%;
            transform: translate(100px) rotate(5deg);

            @media only screen and (min-width: calc($mobile + 1px)) {
                top: unset;
            }

            @media (min-width: calc($mobile + 1px)) and (max-width: $tablet) {
                bottom: 20%;
                left: 63%;
            }

            @media only screen and (min-width: calc($tablet + 1px)) {
                bottom: -10%;
                left: 69%;
            }

            &::after {
                height: 142px;
                width: 160px;
                background: center / cover no-repeat url('data-url:resources/images/olympics/olympics-carousel-flyout.svg');
                transform-origin: 0;
                left: 0;
                top: 0;
                transform: translate(-70%, -80%);
            }
        }
    }

    .flyout-olympics-bl {
        .cmp-teaser__pretitle {
            width: 200px;
            top: -4%;
            left: 10%;
            rotate: 4deg;

            @media only screen and (min-width: calc($mobile + 1px)) {
                top: unset;
                bottom: -10%;
                left: 57%;
            }

            @media (min-width: calc($mobile + 1px)) and (max-width: $tablet) {
                bottom: 25%;
                left: 50%;
            }

            &::after {
                background: center / cover no-repeat url('data-url:resources/images/olympics/olympics-carousel-flyout-straight.svg');
                top: 0;
                left: 0;
                width: 119px;
                height: 102px;
                transform: translate(50%, -100%);
            }
        }
    }
}