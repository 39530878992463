$btn-colors: (
  "almond": $almond,
  "apple": $apple,
  "berry": $berry,
  "broccoli": $broccoli,
  "grape": $grape,
  "mango": $mango,
  "red": $red,
  "spinach": $spinach,
  "water": $water,
  "white": $white,
);

//colors
.btn-center {
  text-align: center;
}

.btn-right {
  text-align: right;
}

.btn-default {

  .cmp-button {
    min-width: 165px;
    height: 44px;
    padding: 0;
    position: relative;
    display: inline-block;
    text-decoration: none;
    line-height: 1;
    text-align: center;
    background: transparent;
    border: 0;
    cursor: pointer;
    outline: 0;
    vertical-align: middle;
    white-space: nowrap;
    padding-right: 50px;

    .button-background {
      transition: fill 0.5s ease, stroke 0.5s ease;
      position: absolute;
      right: 0;
      width: 100%;
      height: 100%;
    }

    .button-icon {
      position: absolute;
      top: 50%;
      right: 7px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      -webkit-transition: fill 0.5s ease;
      transition: fill 0.5s ease;
      margin-right: 10px;
    }

    .cmp-button__text {
      padding-left: 13px;
      padding-right: 20px;
      color: map-get($colors, sharpieBlack);
      @include font-innocent-bold;
      font-size: 19px;
      line-height: 46px;
      -webkit-transition: color 0.5s ease;
      transition: color 0.5s ease;
      position: relative;
      z-index: 2;
    }
  }
}

@each $key,
$val in $btn-colors {

  //for button colors
  .btn-#{$key} {
    .button-background {
      fill: $val;
    }
  }

  //btn-fill
  .btn-fill.btn-#{$key} {
    .cmp-button {
      .cmp-button__text {
        color: $white;
      }

      .button-icon {
        fill: $white;
      }

      .button-background {
        fill: #{$val};
      }
    }
  }

  //btn-fill on hover
  .btn-fill:hover.btn-#{$key} {
    .cmp-button {
      .button-background {
        fill: transparent;
        stroke: #{$val};
      }
    }

    .cmp-button__text {
      color: $black;
    }

    .button-icon {
      fill: $black;
    }
  }

  //btn-stroke
  .btn-stroke.btn-#{$key} {
    .cmp-button {
      .button-background {
        fill: transparent;
        stroke: #{$val};
      }
    }

    .cmp-button__text {
      color: $black;
    }

    .button-icon {
      fill: $black;
    }
  }

  //btn-stroke on hover
  .btn-stroke:hover.btn-#{$key} {
    .cmp-button {
      .cmp-button__text {
        color: $white;
      }

      .button-icon {
        fill: $white;
      }

      .button-background {
        fill: #{$val};
      }
    }
  }
}


//for white fill
.btn-fill.btn-white {
  .cmp-button {
    .cmp-button__text {
      color: $black;
    }

    .button-icon {
      fill: black;
    }

    .button-background {
      fill: white;
    }
  }
}

.btn-fill:hover.btn-white {
  .cmp-button {
    .button-background {
      fill: transparent;
      stroke: white;
    }
  }

  .cmp-button__text {
    color: $white;
  }

  .button-icon {
    fill: $white;
  }
}

//for white stroke
.btn-stroke.btn-white {
  .cmp-button {
    .button-background {
      fill: transparent;
      stroke: white;
    }
  }

  .cmp-button__text {
    color: $white;
  }

  .button-icon {
    fill: $white;
  }
}

.btn-stroke:hover.btn-white {
  .cmp-button {
    .cmp-button__text {
      color: $black;
    }

    .button-icon {
      fill: $black;
    }

    .button-background {
      fill: white;
    }
  }
}