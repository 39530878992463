.title {
    &[class*="t-marker"],
    &[class*="t-olympics"] {
        .cmp-title__text {
            display: grid;
            grid-template-columns: 21px auto 21px;
            align-items: center;
            justify-content: center;

            .title-marker-left {
                order: -1;
                margin-left: -10px;
            }

            .title-marker-right {
                margin-left: 10px;
            }

            svg {
                width: 21px;
            }
        }
    }

    &[class*="t-olympics"] {
        .cmp-title__text {
            svg {
                width: 26px; 
            }
        }
    }
}

@each $key,
$val in $colors {
    .t-marker-#{$key} {
        .cmp-title__text {
            svg {
                fill: #{$val};
            }
        }
    }
}