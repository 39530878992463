.aem-AuthorLayer-Edit {
  .c-kids-entry-page {
    > .cmp-container {
      height: auto;
    }
  }
}

.c-kids-entry-page {
  > .cmp-container {
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (orientation: landscape) and (max-height: 500px) {
      display: block;
      height: auto;
    }
  }

  .t-kids-entry {
    @media (orientation: landscape) and (max-height: 500px) {
      padding-top: 100px;
    }
    .cmp-teaser {
      display: flex;
      flex-direction: column-reverse;

      @media only screen and (max-width: $tablet) {
        padding: 0 20px;
      }
    }

    .cmp-teaser__content {
      max-width: 540px;
      margin: 60px auto 0 auto;

      @media (orientation: landscape) and (max-height: 500px) {
        max-width: 410px;
      }

      @media only screen and (max-width: $tablet) {
        margin: 20px auto 0 auto;
      }
    }

    .cmp-teaser__description p {
      @include font-innocent-bold;
      font-size: 28px;
      line-height: 34px;

      @media only screen and (max-width: $tablet) {
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 20px;
      }
    }

    .cmp-teaser__action-link {
      font-size: 20px;
    }

    .cmp-image {
      margin: 0 auto;
      display: block;
      max-width: 120px;
    }

    .cmp-teaser__action-container {
      @include font-innocent-bold;
      font-size: 20px;
      text-decoration: none;
      display: inline-block;
      border-radius: 60px;
      padding: 10px 25px;
      position: relative;
      cursor: pointer;

      &:before,
      &:after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        border-radius: 60px;
        transition: background-color 0.3s ease-in-out;
      }

      &:before {
        background-color: map-get($kids-colors, sra);
        top: 0;
        left: 0;
        z-index: 2;
      }

      &:after {
        top: 6px;
        left: 6px;
      }

      &:hover {
        &:after {
          background-color: map-get($kids-colors, apple);
        }
      }
    }

    .cmp-teaser__action-link {
      position: relative;
      z-index: 2;
      padding: 0;
      margin-left: 0;

      &:hover {
        color: inherit;
      }
    }

    .button-background,
    .button-icon {
      display: none;
    }
  }

  .ai-middle-left {
    position: initial;

    .cmp-adaptive-image {
      position: absolute;
      top: 0;
      left: 130px;
      width: 270px;

      @media only screen and (max-width: $tablet) {
        width: 200px;
        left: 0;
      }

      @media only screen and (max-width: $mobile) {
        width: 140px;
      }
    }
  }

  .ai-kids-top-right {
    position: initial;

    .cmp-adaptive-image {
      position: absolute;
      top: 0;
      right: 0;
      width: 320px;

      @media only screen and (max-width: $tablet) {
        width: 230px;
      }

      @media only screen and (max-width: $mobile) {
        width: 130px;
      }
    }
  }

  .ai-kids-bottom-left {
    position: initial;

    .cmp-adaptive-image {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 320px;

      @media only screen and (max-width: $tablet) {
        width: 280px;
      }

      @media only screen and (max-width: $mobile) {
        width: 150px;
      }
    }
  }

  .ai-kids-bottom-right {
    position: initial;

    .cmp-adaptive-image {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 320px;

      @media only screen and (max-width: $tablet) {
        width: 230px;
      }

      @media only screen and (max-width: $mobile) {
        display: none;
      }
    }
  }
}
