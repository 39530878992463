.c-simple-carousel {
    // transition: transform 0.3s ease-out;

    > .cmp-container {
        // display: flex;
        transition: transform 300ms ease-out;

        > .image {
            width: 280px;
            // margin: 0 0.938rem;
            // flex: 0 0 auto;
        }

        .cmp-image__link {
            position: relative;
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            margin: auto;
            object-fit: cover;
            -webkit-mask: url(data-url:resources/images/general/masks/slider-social-mask-image.svg) center / contain no-repeat;
            mask: url(data-url:resources/images/general/masks/slider-social-mask-image.svg) center / contain no-repeat;
        }

        .cmp-image__image {
            position: absolute;
            top: 0;
            left: 0;
            max-width: 100%;
            height: 100%;
        }
    }   
}
