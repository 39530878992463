.btn-olympics {

  text-align: center;

  .cmp-button {
    min-width: 311px;
    height: 65px;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    cursor: pointer;
    color: $dark-pink;

    @media only screen and (min-width: $mobile) {
      transition: color .5s;
    }

    .button-background {
      position: absolute;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      stroke: $dark-pink;
      fill: #fff;

      @media only screen and (min-width: $mobile) {
        transition: fill 0.5s ease, stroke 0.5s ease;
      }
    }

    .cmp-button__text {
      color: $dark-pink;
    }

    .button-icon {
      stroke: $dark-pink;
      fill: none;
      
      @media only screen and (min-width: $mobile) {
        transition: stroke .5s;
      }
    }

    .cmp-button__text {
      font-size: 30px;
      margin-right: 20px;
      line-height: 1;
      position: relative;
      top: -3px;
      display: flex;
    }
  }

  .cmp-button {

    &:hover {
      color: $white;

      .cmp-button__text {
        color: $white;
      }

      .button-background {
        stroke: transparent;
        fill: $dark-pink;
      }

      .button-icon {
        stroke: $white;
      }
    }
  }
}
