.ai-kids-bottom-left {
    position: relative;

    .cmp-image__image {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 320px;

        @media only screen and (max-width: $tablet) {
            width: 280px;
            bottom: -65px;
        }

        @media only screen and (max-width: $mobile) {
            width: 180px;
            bottom: -40px;
        }
    }
}