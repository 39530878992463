.btn-social-icons {
  display: inline-block;
  margin-top: 40px;

  .cmp-button {
    .cmp-button__icon {
      width: 100px;
      height: 100px;
      display:flex;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      @media only screen and (max-width: $tablet) {
        width: 100px;
        height: 100px;
      }
      @media only screen and (max-width: $mobile) {
        width: 70px;
        height: 70px;
      }
    }
    .cmp-button__text {
      display: none;
    }
    .cmp-button__icon--facebook {
      background-image: url("data-url:resources/images/general/icons/social_facebook_black.png");
    }
    .cmp-button__icon--x-social {
      background-image: url("data-url:resources/images/general/icons/social_x_black.png");
    }
    .cmp-button__icon--instagram {
      background-image: url("data-url:resources/images/general/icons/social_instagram_black.png");
    }
    .cmp-button__icon--tiktok {
      background-image: url("data-url:resources/images/general/icons/social_tiktok_black.png");
    }
  }
}
