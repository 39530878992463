[class*="aem-AuthorLayer"] {
    .t-wild-area  {
        svg {
            display: none;
        }

        .cmp-teaser {
            height: auto !important;
        }
    }

}

.t-wild-area {

    .cmp-teaser {
        height: 100vh;
        position: relative;
        overflow: hidden;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .cmp-teaser__content {
        width: 60vw;
    }

    .cmp-teaser__image {
        order: -1;
        max-width: 350px;

        .cmp-image__image {
            width: 390px;
            height: auto;

            @media only screen and (max-width: $tablet) {
                max-width: 225px;
            }
        }
    }


    .cmp-teaser__title {
        margin-bottom: 40px;
        font-size: 1.25rem;
        line-height: 1.625rem;

        @media only screen and (min-width: $tablet) {
            font-size: 1.75rem;
            line-height: 2.188rem;
        }
    }

    .top-right-flower {
        transform-origin: top;
        animation: rotate 6s ease infinite;
    }

    .top-left-flower {
        transform-origin: top left;
        animation: rotate 6s ease infinite;
    }

    .left-top-flower {
        transform-origin: top bottom;
        animation: rotate 6s ease infinite;
    }

    .left-bottom-flower {
        transform-origin: top bottom;
        animation: rotate 6s ease infinite;
    }

    .left-leaves {
        transform-origin: right;
        animation: rotate 6s ease infinite;
    }

    .bottom-left-flower {
        transform-origin: bottom;
        animation: rotate 6s ease infinite;
    }

    .bottom-right-flower {
        transform-origin: right;
        animation: rotate 6s ease infinite;
    }

    .flower-bottom-green {
        transform-origin: right;
        animation: rotate 6s ease infinite;
    }

    .right-bottom-flower {
        transform-origin: top;
        animation: rotate 6s ease infinite;
    }

    .right-top-flower {
        transform-origin: top;
        animation: rotate 6s ease infinite;
    }

    .portrait-top-flower {
        transform-origin: top;
        animation: rotate 6s ease infinite;
    }

    .portrait-left-leaves {
        transform-origin: top;
        animation: rotate 6s ease infinite;
    }

    .portrait-left-flower {
        transform-origin: top;
        animation: rotate 6s ease infinite;
    }

    .portrait-bottom-flower {
        transform-origin: top;
        animation: rotate 6s ease infinite;
    }

    .portrait-right-flower {
        transform-origin: top;
        animation: rotate 6s ease infinite;
    }

    .portrait-top {
        position: absolute;
        width: 100vw;
        height: 30vh;
        top: 0;

        animation-name: slideOutUp;
    }

    .portrait-right {
        position: absolute;
        height: 100vh;
        width: 20vh;
        right: 0;

        animation-name: slideOutRight;
    }

    .portrait-bottom {
        position: absolute;
        width: 100vw;
        bottom: 0;
        height: 38vh;

        animation-name: slideOutDown;
    }

    .portrait-left {
        position: absolute;
        height: 100vh;
        width: 20vh;
        left: 0;

        animation-name: slideOutLeft;
    }


    .landscape-top {
        position: absolute;
        width: 100vw;
        height: 25vw;
        top: 0;
        left: 0;
        right: 0;

        animation-name: slideOutUp;
    }

    .landscape-right {
        position: absolute;
        width: 15vw;
        height: 100vh;
        right: 0;
        top: 0;

        animation-name: slideOutRight;
    }

    .landscape-bottom {
        position: absolute;
        width: 100vw;
        bottom: 0;
        height: 25vw;

        animation-name: slideOutDown;
    }

    .landscape-left {
        position: absolute;
        width: 15vw;
        height: 100vh;
        left: 0;
        top: 0;

        animation-name: slideOutLeft;
    }

    .landscape-top,
    .landscape-left,
    .landscape-bottom,
    .landscape-right,
    .portrait-top,
    .portrait-left,
    .portrait-bottom,
    .portrait-right {
        animation-delay: 1s;
        animation-duration: 1.5s;
        animation-fill-mode: both;
    }

    .top-right-flower {
        transform-origin: top;
        animation: rotate 6s ease infinite;
    }

    .top-left-flower {
        transform-origin: top left;
        animation: rotate 6s ease infinite;
    }

    &.t-btn-blooming {
        .cmp-teaser__action-link {
            position: relative;
            height: 44px;
            padding-left: 30px;

            &:hover {
                background: $apple;
                border-radius: 50px;
                color: #fff;
            }

            .button-background {
                fill: rgba(171, 92, 87, 0);
                stroke: $apple;
            }

            .button-background:hover {
                fill: $apple;
                stroke: $apple;
            }

            &:after,
            &:before {
                content: "";
                display: block;
                position: absolute;
                pointer-events: none;
                animation-duration: 0.3s;
                animation-timing-function: ease-in-out;
                animation-fill-mode: forwards;
            }

            &:before {
                z-index: -1;
                left: -20px;
                top: -30px;
            }

            &:after {
                z-index: -1;
                right: -20px;
                top: -24px;
            }


            &.zoom-in {
                &:before {
                    content: '';
                    width: 69px;
                    height: 97px;
                    background-image: url(data-url:resources/images/rewild/button/flower-left.svg);
                    z-index: 2;
                    animation-name: zoomIn;
                }

                &:after {
                    content: '';
                    width: 43px;
                    height: 55px;
                    background-image: url(data-url:resources/images/rewild/button/flower-right.svg);
                    z-index: 2;
                    animation-name: zoomIn;
                }
            }

            &.zoom-out {
                &:before {
                    content: '';
                    width: 69px;
                    height: 97px;
                    background-image: url(data-url:resources/images/rewild/button/flower-left.svg);
                    z-index: 2;
                    animation-name: zoomOut;
                }

                &:after {
                    content: '';
                    width: 43px;
                    height: 55px;
                    background-image: url(data-url:resources/images/rewild/button/flower-right.svg);
                    z-index: 2;
                    animation-name: zoomOut;
                }
            }
        }
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }

    50% {
        transform: rotate(6deg);
    }

    100% {
        transform: rotate(0);
    }
}

@keyframes slideOutDown {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }

    to {
        -webkit-transform: translate3d(0, var(--slide-bottom), 0);
        transform: translate3d(0, var(--slide-bottom), 0);

    }
}

@keyframes slideOutUp {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }

    to {
        -webkit-transform: translate3d(0, var(--slide-top), 0);
        transform: translate3d(0, var(--slide-top), 0);

    }
}


@keyframes slideOutLeft {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }

    to {
        -webkit-transform: translate3d(var(--slide-left), 0, 0);
        transform: translate3d(var(--slide-left), 0, 0);

    }
}

@keyframes slideOutRight {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }

    to {
        -webkit-transform: translate3d(var(--slide-right), 0, 0);
        transform: translate3d(var(--slide-right), 0, 0);

    }
}

@keyframes zoomIn {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }

    50% {
        opacity: 1;
    }
}

@keyframes zoomOut {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }

    to {
        opacity: 0;
    }
}

@media (orientation: portrait) {

    .landscape-bottom,
    .landscape-left,
    .landscape-right,
    .landscape-top {
        display: none;
    }

    :root {
        --slide-left: -20%;
        --slide-right: 40%;
        --slide-bottom: 25%;
        --slide-top: -25%;
    }
}

@media (orientation: landscape) {

    .portrait-bottom,
    .portrait-left,
    .portrait-right,
    .portrait-top {
        display: none;
    }

    :root {
        --slide-left: -10%;
        --slide-right: 10%;
        --slide-bottom: 30%;
        --slide-top: -30%;
    }
}