[class*="aem-AuthorLayer"] {
  .c-contact-phone,
  .c-contact-email,
  .c-contact-address {
    > .cmp-container {
      height: auto;
    }
  }
}

.c-contact-phone,
.c-contact-email,
.c-contact-address {
  > .cmp-container {
    position: relative;
    height: 100vh;
    min-height: 700px;
    background-color: transparent !important;
  }
}

.c-contact-phone {
  > .cmp-container {
    @media only screen and (max-width: $mobile) {
      display: flex;
      align-items: center;
    }
  }
  
  h4.cmp-title__text {
    margin-top: 120px;

    @media only screen and (max-width: $mobile) {
      margin-top: 0;
    }
  }

  #banana-phone-cord {
    background-image: url("data-url:resources/images/general/contact/banana-phone-cord.svg");
    background-repeat: no-repeat;
    position: absolute;
    width: 450px;
    height: 254px;
    top: 350px;
    right: -50px;

    svg {
      position: absolute;
      top: -240px;
      left: -18px;

      @media only screen and (max-width: $laptop) {
        top: -190px;
        left: 9px;
        width: 90px;
      }
    }

    @media only screen and (max-width: $laptop) {
      width: 200px;
      top: 170px;
      left: calc(100% - 100px);
      background-size: 100%;
    }
  }
}

.c-contact-email {
  h2 {
    margin-top: 75px;
    color: $water;
  }

  h4 {
    a {
      color: $white;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  #plane-1 {
    background-image: url("data-url:resources/images/general/contact/sprite-plane-1.png");
    background-repeat: no-repeat;
    width: 311px;
    height: 361px;
    position: absolute;
    bottom: 30px;
    left: 30px;
    -webkit-animation: plane-1 4s steps(121) infinite;
    animation: plane-1 4s steps(121) infinite;

    @media only screen and (max-width: $laptop) {
      transform: translate(-50%, 15%) scale(0.75);
    }
  }

  #plane-2 {
    background-image: url("data-url:resources/images/general/contact/sprite-plane-2.png");
    background-repeat: no-repeat;
    position: absolute;
    width: 409px;
    height: 178px;
    top: 385px;
    left: 50%;
    animation: plane-2 4s steps(121) infinite;
    transform: translate(-50%, -200%);

    @media only screen and (max-width: $laptop) {
      transform: translate(-50%, -150%) scale(0.75);
      top: 300px;
      bottom: 80px;
    }
  }

  #plane-3 {
    background-image: url("data-url:resources/images/general/contact/sprite-plane-3.png");
    background-repeat: no-repeat;
    position: absolute;
    width: 400px;
    height: 249px;
    bottom: 30px;
    right: 0;
    animation: plane-3 4s steps(121) infinite;

    @media only screen and (max-width: $laptop) {
      display: none;
    }
  }
}

.c-contact-address {

  padding-bottom: 200px;
  position: relative  ;
    > svg {
      position: absolute;
      top:0;
    }
    .st1 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: $white;
    }

  .st2 {
      stroke-linejoin: round
  }

  .st2,.st3 {
      fill: none;
      stroke: #1d1d1b;
      stroke-width: 3.525;
      stroke-linecap: round
  }

  .st5,.st6 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: #c6c6c5
  }

  .st6 {
      fill: #a22035
  }
  
  .cmp-text {
    text-align: center;
  }
}


@keyframes plane-1 {
  0% {
    background-position-y: 0;
  }

  to {
    background-position-y: -43681px;
  }
}

@keyframes plane-2 {
  0% {
    background-position-y: 0;
  }

  to {
    background-position-y: -21538px;
  }
}

@keyframes plane-3 {
  0% {
    background-position-y: 0;
  }

  to {
    background-position-y: -30129px;
  }
}


@media only screen and (max-width: $mobile) {
  .c-contact-address {
    position: relative ;
      > svg {
      position: absolute;
      top: 48%;
      right: 0;
      width: 768px;
      }
}
}

