.c-olympics-boxes {
    background: rgb(236, 185, 204);
    position: relative;
    padding-bottom: 11%;
    
    @media only screen and (max-width: $tablet) {
        padding-bottom: 245px;  /* svg height */
    }

    &:before {
        content: "";
        display: block;
        padding-top: calc((65 / 1440) * 100%);  /* Aspect ratio padding-top: height 65, width 1440 */
        background-position: center center;
        background-size: contain;
        background-image: url(data-url:resources/images/olympics/pink-background.svg);
        background-size: cover;
    }

    &:after {
        content: "";
        display: block;
        padding-top: calc((287 / 1440) * 100%);  /* Aspect ratio padding-top: height 287, width 1440 */
        background-position: center center;
        background-size: cover;
        background-image: url(data-url:resources/images/olympics/supporters-2.svg);
        position: absolute;
        bottom: 0;
        width: 100%;

        @media only screen and (max-width: $tablet) {
            background-image: url(data-url:resources/images/olympics/supporters-2-mobile.svg);
            padding-top: 245px;  /* svg height */
        }
    }
    
    .cmp-container>.container>.cmp-container {
        counter-reset: text-list-number;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 60px; 

        @media only screen and (max-width: $mobile) {
            flex-direction: column;
        }

        >.text {
            width: 440px;
            height: 180px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            background-repeat: no-repeat;
            background-position: center;
            margin-bottom: 60px;

            @media only screen and (max-width: $mobile) {
                width: 340px;
                height: 190px;
                margin: 0 auto 30px;
            }

            &::before {
                counter-increment: text-list-number;
                content: counter(text-list-number) " ";
                font-size: 28px;
                @include font-innocent-bold;
                position: absolute;
                top: 0;
                right: 50%;
                transform: translate(50%, -50%);
                z-index: 9;
                @media only screen and (max-width: $mobile) {
                    top: 11px;
                }
            }

            &::after {
                content: "";
                background-image: url("data-url:resources/images/general/icons/olympics-circle-icon.svg");
                background-repeat: no-repeat;
                position: absolute;
                background-size: 100%;
                width: 55px;
                height: 56px;
                top: 0;
                right: 50%;
                transform: translate(50%, -50%);
                @media only screen and (max-width: $mobile) {
                    top: 15px;
                }
            }

            .cmp-text {
                width: 300px;
            }

            p {
                color: rgb(32, 39, 33);
                text-align: center;
                padding: 10px;
                font-size: 24px;
                line-height: 26px;
                font-weight: 300;
                margin: 0;
            }

            &:nth-child(1) {
                background-image: url('data-url:resources/images/olympics/text-block-1.svg');
            }

            &:nth-child(2) {
                background-image: url('data-url:resources/images/olympics/text-block-2.svg');
            }

            &:nth-child(3) {
                background-image: url('data-url:resources/images/olympics/text-block-3.svg');

                @media only screen and (max-width: $tablet) {
                    margin-bottom: 40px;
                }
            }
        }
    }

   >.cmp-container >.text {
        display: flex;
        justify-content: center;
        align-items: center;

        .cmp-text {
            width: 356px;
            text-align: center;
            position: relative;
            z-index: 1;

            p {
                line-height: 16px;
                color: rgb(32, 39, 33);
            }
        }
    }
}