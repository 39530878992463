.c-grid-up-down-4 {
  > .cmp-container {
      @media (min-width: $mobile) {
        display: grid;
        grid-gap: 60px;
        grid-template-columns: repeat(4, 1fr); 
      }
  }

  .teaser,
  .adaptiveImage {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 50px;

    > .cmp-teaser {
      width: 100%;
      padding: 0;
    }
  }

  .cmp-container > .teaser:nth-child(even),
  .cmp-container > .adaptiveImage:nth-child(even)  {
    @media (min-width: $mobile) {
        transform: translateY(30px);
    }
  }

  .adaptiveImage {
    @media (max-width: $mobile) {
      max-width: 340px;
      margin-left: auto;
      margin-right: auto;
    } 
  }
}
