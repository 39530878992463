.c-nature-section-header {
    background-image: url(data-url:resources/images/kids/icons/kids-nature-section-header-module-bg.svg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-bottom: 100px;
    position: relative;
    padding-bottom: 120px;
    margin-top: 100px;

    @media only screen and (max-width: $mobile) {
        background-image: url(data-url:resources/images/kids/icons/nature-section-header-mobile-background.svg);
        padding: 200px 0;
        margin-top: 70px;
    }

    .ai-kids-top-right {
        position: absolute;
        top: -60px;
        right: 0;

        @media only screen and (max-width: $mobile) {
            top: -40px;
        }

        picture {
            @media only screen and (max-width: $tablet) {
                width: 200px;
                display: block;
            }
        }
    }

    .ai-kids-bottom-left {
        width: 230px;
        position: absolute;
        left: 0px;
        bottom: -180px;

        @media only screen and (max-width: $tablet) {
            bottom: -150px;
        }

        picture {
            @media only screen and (max-width: $tablet) {
                width: 200px;
                display: block;
            }
        }

        @media only screen and (max-width: $mobile) {
            bottom: -140px;
        }
    }
}