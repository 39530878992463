  @font-face {
    font-family: Innocent Super Scrawl;
    src: url("resources/fonts/innocentsupers-scrawl.woff2") format("woff2"),
    url("resources/fonts/innocentsupers-scrawl.woff") format("woff");
    font-weight: normal; 
    font-style: normal;
  }
  
  @mixin font-innocent-super-scrawl {
    font-family: Innocent Super Scrawl, sans-serif;
    font-weight: 100;
    font-style: normal;
  }
  
  @font-face {
    font-family: Innocent Bold;
    src: url("resources/fonts/innocent-Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal; 
  }

  @mixin font-innocent-bold {
    font-family: Innocent Bold, sans-serif;
    font-weight: normal; 
    font-style: normal;
  }
  
  @font-face {
    font-family: Innocent Light;
    src: url("resources/fonts/innocent-Light.woff2") format("woff2");
    font-weight: 100;
    font-style: normal;
  }
  
  @mixin font-innocent-light {
    font-family: Innocent Light, sans-serif;
    font-weight: normal;
    font-style: normal;
  }
  