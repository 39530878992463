.t-kids-vertical-r {


    .cmp-teaser__content {
        max-width: 540px;
        margin: 0 auto;
        @media only screen and (max-width: $tablet) {
            margin: 40px auto 0 auto;
        }
    }

    .cmp-teaser__title {
        font-size: 48px;
        line-height: 58px;
        @media only screen and (max-width: $tablet) {
            font-size: 38px;
            line-height: 46px;
        }
        
    }
    .cmp-teaser__description p {
        font-size: 22px;
        line-height: 32px;
    }

    .cmp-teaser {
        display: flex;
        flex-direction: column-reverse;
        @media only screen and (max-width: $tablet) {
           padding: 0 20px;
        }
    }

    .cmp-image__image {
        max-width: 540px;
        display: block;
        margin: 0 auto;
        @media only screen and (max-width: $tablet) {
            max-width: 369px;
        }
    }
}